<template>
  <v-app>
    <Navbar />
    <v-main class="main" >
      <LoadingBar />
      <router-view class="router" />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import LoadingBar from "@/components/LoadingBar";

export default {
  name: "App",
  components: { Navbar, LoadingBar },
  data: () => ({
    //
  }),
  created() {
    //update entity_root in store
    this.$store.commit(
      "changeEntityRoot",
      this.$keycloak.tokenParsed.entity_root
    );
    //get defaults entitieStatus
    this.getStatus();
    this.getTypes();
    this.getAclTypes();
  },
  methods: {
    //get default status for entities on app loading
    getStatus() {
      this.$http({
        method: "get",
        url: `/api/entities/status`,
      })
        .then((result) => {
          this.$store.commit("updateEntitiesStatus", result.data);
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
    },
    //get default status for entities on app loading
    getTypes() {
      this.$http({
        method: "get",
        url: `/api/entities/types`,
      })
        .then((result) => {
          this.$store.commit("updateEntitiesTypes", result.data);
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
    },
    //get default ACL Type on app loading
    getAclTypes() {
      this.$http({
        method: "get",
        url: `/api/acl/types`,
      })
        .then((result) => {
          this.$store.commit("updateACLTypes", result.data);
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
    },
  },
};
</script>

<style>

.router{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

</style>