<template>
  <div class="session">
    <v-container class="my-5">
      <v-layout row>
        <v-flex class="mt-4 mb-3">
         <v-card
          elevation="1"
        >
        <v-card-title>User {{ this.$keycloak.tokenParsed.name }}</v-card-title>
        <v-card-text>
          <vue-json-pretty :path="'res'" :data="this.$keycloak.tokenParsed"> </vue-json-pretty>
        </v-card-text>
        </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  components: {
    VueJsonPretty,
  },
  data: function () {
    return {}
  }
}
</script>




