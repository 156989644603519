<template>
  <div class="remote">
    <v-container class="">
      <v-row class="" no-gutters>
        <v-col cols="12">
          <Breadcrumbs></Breadcrumbs>
        </v-col>
        <v-col cols="12">
          <div class="mx-3 text-uppercase text-h4">Current Entity</div>
        </v-col>
      </v-row>
      <v-card height="100%" class="mt-4 mb-3">
        <v-row>
          <v-col cols="12" md="6" lg="6" xl="6">
            <v-list-item two-line>
              <div class="left_margin_remote"></div>
              <div  class="icon">
                <v-icon x-large v-if="entity.type=='anchor'"> mdi-folder </v-icon>
                <v-icon x-large v-if="entity.type=='root_asset'"> mdi-folder-open </v-icon>
                <v-icon x-large v-if="entity.type=='client_root'"> mdi-folder-account-outline </v-icon>
                <v-icon x-large v-if="entity.type=='device'"> mdi-devices </v-icon>
                <v-icon x-large v-if="entity.type=='user'"> mdi-account </v-icon>
                <v-icon x-large v-if="entity.type=='asset'"> mdi-folder-open-outline </v-icon>
                <v-icon x-large v-if="entity.type=='app'"> mdi-apps </v-icon>
              </div>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  <div v-if="entity.alias">
                    <span class="">{{ entity.alias }}</span>
                    <span class="font-weight-light"> {{ entity.name }}</span>
                  </div>
                  <div v-else>
                    {{ entity.name }}
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle
                  >{{ entity.type }} - {{ entity.ident }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" md="6" lg="6" xl="6" class="d-flex justify-end mb-6">

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-3"
                  dark
                  color="primary"
                  :to="{ name: 'Entities', params: { ident: entity.ident } }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-devices </v-icon>
                </v-btn>
              </template>
              <span>View entity</span>
            </v-tooltip>

            <div class="margin_right_remote"></div>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
      </v-card>
      <v-card height="100%" class="mt-4 mb-3">

        <v-row v-if="showStatus">
          <div class="session" xs12 sm12 md12>
            <h1 class="subheading grey--text">Sessions</h1>
          </div>

          <v-spacer></v-spacer>

          <div v-if="ovpn == 'unavailable'" class="sessionMenu">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  Basic
                  <div class="blob"></div>
                </v-btn>
              </template>
              <span>Unavailable</span>
            </v-tooltip>
          </div>

          <div v-if="ovpn == 'reachable'" class="sessionMenu">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="createSessionMenu('ovpn')"
                >
                  Basic
                  <div class="blob blue"></div>
                </v-btn>
              </template>
              <span>Reachable</span>
            </v-tooltip>
          </div>


            <v-spacer></v-spacer>

            <div v-if="ssh == 'unavailable'" class="sessionMenu">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                  >
                    Advanced
                    <div class="blob"></div>
                  </v-btn>
                </template>
                <span>Unavailable</span>
              </v-tooltip>
            </div>

            <div v-if="ssh == 'unauthenticated'" class="sessionMenu">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                  >
                    Advanced
                    <div class="blob red"></div>
                  </v-btn>
                </template>
                <span>Unauthenticated</span>
              </v-tooltip>
            </div>

            <div v-if="ssh == 'reachable'" class="sessionMenu">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="createSessionMenu('ssh')"
                  >
                    Advanced
                    <div class="blob blue"></div>
                  </v-btn>
                </template>
                <span>Reachable</span>
              </v-tooltip>
            </div>


          <v-spacer></v-spacer>

          <div style="margin-right: 34px;" class="sessionMenu" xs2 sm4 md12>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="getSessions(entity.ident)"
                >
                  <v-icon dark> mdi-cached </v-icon>
                </v-btn>
              </template>
              <span>Refresh list</span>
            </v-tooltip>
          </div>
          
        </v-row>
          <v-row row v-if="createSessionOvpn">
            <v-col xs2 sm4 md12>
              <RemoteCreateOvpn
                :newSession="this.newSession"
                :entity="this.entity"
                :dialog="this.createSessionOvpn"
                :auto_start="this.auto_start" 
                @clicked="createSessionSubmit"
                @cancelComponent="closeComponent"
              />
            </v-col>
          </v-row>
        <v-row row>

        </v-row>
          <v-row row v-if="createSessionSsh">
            <v-col xs2 sm4 md12>
              <RemoteCreateSsh
                :newSession="this.newSession"
                :entity="this.entity"
                :dialog="this.createSessionSsh"
                :auto_start="this.auto_start" 
                @clicked="createSessionSubmit"
                @cancelComponent="closeComponent"
              />
            </v-col>
          </v-row>
        <v-row row>
          
          <v-col xs2 sm4 md12>
            <RemoteList :sessions="sessions" @updateParent="updateParent"/>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

  </div>
</template>

<script>
import RemoteCreateOvpn from "@/components/RemoteCreateOvpn";
import RemoteCreateSsh from "@/components/RemoteCreateSsh";
import RemoteList from "@/components/RemoteList";
// import MainEntityDetails from "@/components/entities/MainEntityDetails";
import moment from "moment";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  components: { RemoteCreateOvpn, RemoteCreateSsh, RemoteList, Breadcrumbs },
  data() {
    return {
      createSessionOvpn: false,
      createSessionSsh: false,
      refreshInterval: 1000,
      ident: "",
      entity: {},
      sessions: {},
      editDialog: false,
      newSession: {
        ident: this.ident,
        port: "80",
        type: "http",
        ttl: 600,
        ip: "127.0.0.1",
        remoteType: "ovpn",
      },
      ovpn: "unavailable",
      ssh: "unavailable",
      showStatus: false,
      interval: 1,
      auto_start: false
    };
  },
  created() {
    setInterval(() => this.getNotifications(), 1000);
    if (this.$route.params.ident) {
      this.ident = this.$route.params.ident;
      this.goToIdent(this.ident);
    }
    if (this.$route.params.remote_type) {
      this.newSession.remoteType = this.$route.params.remote_type;
      this.createSessionMenu(this.newSession.remoteType)
    }
    this.newSession.ident = this.ident;
    this.getSessions(this.ident);
    this.getDeviceStatus(this.ident);
    this.checkSessions()
    this.checkArgs()
  },
  methods: {
    checkArgs() {

      console.log(this.$route.query.remote)
      console.log(this.$route.query.service)
      console.log(this.$route.query.port)
      console.log(this.$route.query.ttl)
      console.log(this.$route.query.ip)
      console.log(this.$route.query.auto_start)
      console.log(location.href)

      if (this.$route.query.remote == "ssh") {
        this.newSession.port = this.$route.query.port
        this.newSession.type = this.$route.query.service
        this.newSession.ttl = this.$route.query.ttl
        this.newSession.ip = this.$route.query.ip
        this.newSession.remoteType = this.$route.query.remote

        this.createSessionSsh = true
        
      }
      if (this.$route.query.remote == "ovpn") {
        this.newSession.port = this.$route.query.port
        this.newSession.type = this.$route.query.service
        this.newSession.ttl = this.$route.query.ttl
        this.newSession.remoteType = this.$route.query.remote

        this.createSessionOvpn = true
      }

      this.auto_start = this.$route.query.auto_start

    },
    getNotifications(){
      this.checkSessions()
    },
    checkSessions(){
      let object = this.sessions
      for (const property in object) {
        if (object[property].status != "Closed"){
          const date = new Date(object[property].end_time)
          const timestamp = date.getTime();
          if (timestamp < Date.now()){
            this.getSessions(this.ident)
          }
        }
      }
    },
    updateParent(newChildSession, remoteType) {
      this.newSession = newChildSession
      this.newSession.ident = this.ident
      this.createSessionMenuChild(remoteType)
    },
    getDeviceStatus(ident) {
      this.$http({
        method: "get",
        url: `/remote/device/${ident}`,
        data: {},
      })
        .then((res) => {
          for (var j = 0; j < res.data.length; j++) {
            if(res.data[j].remote == "ovpn"){
              this.ovpn = res.data[j].status
            }
          }
          for (var k = 0; k < res.data.length; k++) {
            if(res.data[k].remote == "ssh"){
              this.ssh = res.data[k].status
            }
          }
          this.showStatus = true
          return res.data;
        })
        .catch(function (erreur) {
          console.log(erreur);
        });
    },
    closeComponent() {
      this.createSessionOvpn = false
      this.createSessionSsh = false
    },
    createSessionMenu(remoteType) {
      if (remoteType == "ovpn") {
        if (this.createSessionOvpn) {
          this.createSessionOvpn = false
        }else{
          this.newSession.remoteType = "ovpn"
          this.createSessionOvpn = true
          this.createSessionSsh = false
        }
      }else if(remoteType == "ssh") {
        if (this.createSessionSsh) {
          this.createSessionSsh = false
        }else{
          this.newSession.remoteType = "ssh"
          this.createSessionSsh = true
          this.createSessionOvpn = false
        }
      }
    },
    createSessionMenuChild(remoteType) {
      window.scrollTo(0,0);
      this.createSessionOvpn = false
      this.createSessionSsh = false
      if (remoteType == "ovpn") {
        this.sleep(100)
          .then(() => this.newSession.remoteType = "ovpn")
          .then(() => this.createSessionSsh = false)
          .then(() => this.createSessionOvpn = true)
          .then(() => this.sleep(100))
      }else if(remoteType == "ssh") {
        this.sleep(100)
          .then(() => this.newSession.remoteType = "ssh")
          .then(() => this.createSessionOvpn = false)
          .then(() => this.createSessionSsh = true)
          .then(() => this.sleep(100))
      }
    },
    editDialogVisibility(state) {
      this.editDialog = state;
    },
    goToIdent(ident) {
      this.ident = ident;
      this.getIdentInfo(ident);
    },
    goToError() {
      this.$router.push({ name: "PageNotFound" });
    },
    getIdentInfo(ident) {
      this.$store.commit("showLoader");
      this.$http({
        method: "get",
        url: `/api/entities/${ident}`,
        data: {},
      })
        .then((result) => {
          console.log(result)
          this.$data.entity = result.data;
          this.$store.commit("hideLoader");
        })
        .catch((erreur) => {
          this.goToError()
          console.log(erreur)
          //On traite ici les erreurs éventuellement survenues
        });
    },
    createSessionSubmit() {
      this.createSessionOvpn = false;
      this.createSessionSsh = false;
      this.createSession(this.newSession);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    createSession(newSession) {
      let data
      if (newSession.remoteType == "ovpn") {
        data = {
          trusted: "true",
          ttl: newSession.ttl,
          service_type: newSession.type,
          remote_type: newSession.remoteType,
          value:{
            ovpn_service_port: newSession.port
          }
        }
      }else if (newSession.remoteType == "ssh") {
        data = {
          trusted: "true",
          ttl: newSession.ttl,
          service_type: newSession.type,
          remote_type: newSession.remoteType,
          value:{
            ssh_service_port: newSession.port,
            ssh_service_ip: newSession.ip
          }
        }
      }
      this.dialog = false;
      this.$http({
        method: "post",
        url: "/remote/device/" + newSession.ident + "/session",
        data: data,
      })
        .then(function (result) {
          //On traite la suite une fois la réponse obtenue
          console.log(result.data);
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
      //a revoir
      this.sleep(1000)
        .then(() => this.getSessions(this.ident))
        .then(() => this.sleep(1000))
        .then(() => this.getSessions(this.ident))
        .then(() => this.sleep(1000))
        .then(() => this.getSessions(this.ident))
        .then(() => this.sleep(1000))
        .then(() => this.getSessions(this.ident))
        .then(() => this.sleep(1000))
        .then(() => this.getSessions(this.ident))
        .then(() => this.sleep(1000))
        .then(() => this.getSessions(this.ident))
        .then(() => this.sleep(1000))
        .then(() => this.getSessions(this.ident))
        .then(() => this.sleep(1000))
        .then(() => this.getSessions(this.ident))
        .then(() => this.sleep(1000))
        .then(() => this.getSessions(this.ident))
        .then(() => this.sleep(1000))
        .then(() => this.getSessions(this.ident));
    },
    async getSessionsInfo(ident) {
      const response = await this.$http({
        method: "get",
        url: `/remote/device/${ident}/session`,
        data: {},
        result: {},
      })
        .then((res) => {
          const sortByDate = arr => {
            const sorter = (a, b) => {
              return new Date(a.creation_time).getTime() - new Date(b.creation_time).getTime();
            }
            arr.sort(sorter);
          };
          sortByDate(res.data);
          console.log(res.data);
          res.data = res.data.reverse()
          this.$store.commit("hideLoader");
          let retour = res.data;
          for (let i in retour) {
            //format datetime
            retour[i].creation_time = moment(retour[i].creation_time, "YYYY-MM-DD HH:mm:ss")
            
            //generate end time
            retour[i].end_time = moment(retour[i].creation_time)
                .add(retour[i].ttl, "seconds")
                .local();
          }
          return retour;
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
        return response;
    },
    getSessions(ident){
      this.$store.commit("showLoader");
      Promise.all([this.getSessionsInfo(ident)])
        .then(values => {
          let sessions = values[0]
          this.$store.commit("hideLoader");
          this.sessions = sessions;
          console.log(this.sessions)
        })
        .catch(error => {
          this.errormsg = error.response.data.message;
        });      
    },
  },
};
</script>

<style>

.session {
  margin-left: 18px;
}

.sessionMenu {
  margin-top: 8px;
}

.session.Created {
  border-left: 4px solid #3cd1c2;
  border-top: 4px solid #3cd1c2;
}
.session.requested {
  border-left: 4px solid orange;
  border-top: 1px solid rgb(192, 192, 192)
}
.session.Processing {
  border-left: 4px solid orange;
  border-top: 1px solid rgb(192, 192, 192)
}
.session.Closed {
  border-left: 4px solid rgb(255, 38, 0);
  border-top: 1px solid rgb(192, 192, 192)
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

* {
  box-sizing: border-box;
}

.blobs-container {
  display: flex;
}

.blobs-container2 {
  display: flex;
  margin-right: 15px;
  float: right;
}

.blob {
  background: rgb(192, 192, 192);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 15px;
  width: 15px;
  transform: scale(1);
  margin-right: -5px;
  margin-left: 4px;
  margin-bottom: 11px;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blob.white {
  background: white;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.orange {
  background: rgba(255, 121, 63, 1);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
  animation: pulse-orange 2s infinite;
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}

.blob.yellow {
  background: rgba(255, 177, 66, 1);
  box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
  animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
}

.blob.blue {
  background: rgba(52, 172, 224, 1);
  box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

.blob.purple {
  background: rgba(142, 68, 173, 1);
  box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
  animation: pulse-purple 2s infinite;
}

@keyframes pulse-purple {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
  }
}

footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
}

.loader {
  border-top: 16px solid blue;
  border-right: 16px solid rgb(0, 225, 255);
  border-bottom: 16px solid red;
  border-left: 16px solid rgb(255, 166, 0);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.icon_remote {
  margin-left: 20px;
  margin-right: -30px;
}

.left_margin_remote {
  margin-left: 25px;
}

.margin_right_remote {
  margin-right: 25px;
}

</style>