<template>
  <div class="session">
    <v-container class="">
      <v-row class="" no-gutters>
        <v-col col="12">
          <v-row no-gutters>
            <v-col cols="12">
              <v-col>
                <Breadcrumbs></Breadcrumbs>
              </v-col>
            </v-col>
          </v-row>
          
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header @click.native="hideAccordion()">
                <v-row>
                  <v-col cols="12" md="6" lg="6" xl="6">
                    <v-list-item two-line>
                      <div class="left_margin"></div>
                      <div  class="icon">
                        <v-icon x-large v-if="entity.type=='anchor'"> mdi-folder </v-icon>
                        <v-icon x-large v-if="entity.type=='root_asset'"> mdi-folder-open </v-icon>
                        <v-icon x-large v-if="entity.type=='client_root'"> mdi-folder-account-outline </v-icon>
                        <v-icon x-large v-if="entity.type=='device'"> mdi-devices </v-icon>
                        <v-icon x-large v-if="entity.type=='user'"> mdi-account </v-icon>
                        <v-icon x-large v-if="entity.type=='asset'"> mdi-folder-open-outline </v-icon>
                        <v-icon x-large v-if="entity.type=='app'"> mdi-apps </v-icon>
                      </div>
                      <v-list-item-content>
                        <v-list-item-title class="text-h5">
                          <div v-if="entity.alias">
                            <span class="">{{ entity.alias }}</span>
                            <span class="font-weight-light">
                              {{ entity.name }}</span
                            >
                          </div>
                          <div v-else>
                            {{ entity.name }}
                          </div>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >{{ entity.type }} -
                          {{ entity.ident }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    class="d-flex justify-end mb-6"
                  >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-3"
                          dark
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click.native.stop
                          v-on:click="createDialog = true"
                          :disabled="entity.type == 'device' || entity.type == 'app' || entity.type == 'user'"
                        >
                          <v-icon> mdi-plus </v-icon>
                        </v-btn>
                      </template>
                      <span>Create child entity</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <!-- Entity details menu -->
              <v-expansion-panel-content class="pl-10">
                <EntityEdit v-bind:entity="entity"></EntityEdit>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <!-- If children print them -->
      <!-- <div v-if="entity.children.length > 0"> -->
      <!-- Accordion for entity list -->
      <v-expansion-panels focusable>
        <v-expansion-panel
          v-for="childEntity in entity.children"
          :key="childEntity.ident"
          :class="`mt-4 mb-3 ml-10 entity ${childEntity.status}`"
        >
          <!-- Entity Title bar -->
          <v-expansion-panel-header @click.native="hideAccordion()" @click="getstatus(childEntity.ident)">
            <v-col>
              <v-list-item two-line>
                <v-icon x-large v-if="childEntity.type=='anchor'" class="icon"> mdi-folder </v-icon>
                <v-icon x-large v-if="childEntity.type=='root_asset'" class="icon"> mdi-folder-open </v-icon>
                <v-icon x-large v-if="childEntity.type=='client_root'" class="icon"> mdi-folder-account-outline </v-icon>
                <v-icon x-large v-if="childEntity.type=='device'" class="icon"> mdi-devices </v-icon>
                <v-icon x-large v-if="childEntity.type=='user'" class="icon"> mdi-account </v-icon>
                <v-icon x-large v-if="childEntity.type=='asset'" class="icon"> mdi-folder-open-outline </v-icon>
                <v-icon x-large v-if="childEntity.type=='app'" class="icon"> mdi-apps </v-icon>
                <v-list-item-content>
                  
                  <v-list-item-title class="text-h5">
                    
                    <div v-if="childEntity.alias">
                      <span class="">{{ childEntity.alias }}</span>
                      <span class="font-weight-light">
                        {{ childEntity.name }}</span
                      >
                    </div>
                    <div v-else>
                      {{ childEntity.name }}
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >{{ childEntity.type }} -
                    {{ childEntity.ident }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" lg="4" xl="4" class="d-flex justify-end">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-3"
                    dark
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click.native.stop
                    :to="{
                      name: 'Remote',
                      params: { ident: childEntity.ident },
                    }"
                    :disabled="childEntity.type != 'device'"
                    
                  >
                    <v-icon> mdi-router </v-icon>
                  </v-btn>
                </template>
                <span>Remote access</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-3"
                    dark
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click.native.stop
                    :to="{
                      name: 'Entities',
                      params: { ident: childEntity.ident },
                    }"
                    :disabled="childEntity.type == 'device' || childEntity.type == 'app' || childEntity.type == 'user'"
                  >
                    <v-icon dark> mdi-arrow-right-bold </v-icon>
                  </v-btn>
                </template>
                <span>Go to Children</span>
              </v-tooltip>
            </v-col>
          </v-expansion-panel-header>
          <!-- Entity details menu -->
          <v-expansion-panel-content class="pl-10">

            <div v-if="showStatus == false"> 
              <div v-if="childEntity.type == 'device'" >
                  <div class="blobs-container2" id="app">

                    <v-btn
                      outlined
                      rounded
                      text
                    >
                      Basic
                      <div class="blob"></div>
                    </v-btn>

                    <v-btn
                      outlined
                      rounded
                      text
                    >
                      Advanced
                      <div class="blob"></div>
                    </v-btn>

                  </div>
              </div>
            </div>

            <div v-if="showStatus"> 
              <div v-if="childEntity.type == 'device'" >
                  <div class="blobs-container2" id="app">
                    
                    <v-tooltip bottom v-if="ovpn == 'unavailable'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          rounded
                          text
                          v-bind="attrs"
                          v-on="on"
                        >
                          Basic
                          <div class="blob"></div>
                        </v-btn>
                      </template>
                      <span>Unavailable</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="ovpn == 'unauthenticated'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          rounded
                          text
                          v-bind="attrs"
                          v-on="on"
                        >
                          Basic
                          <div class="blob red"></div>
                        </v-btn>
                      </template>
                      <span>Unauthenticated</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="ovpn == 'reachable'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          rounded
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click.native.stop
                          :to="{
                            name: 'Remote',
                            params: { ident: childEntity.ident, remote_type: 'ovpn' },
                          }"
                          :disabled="childEntity.type != 'device'"
                        >
                          Basic
                          <div class="blob blue"></div>
                        </v-btn>
                      </template>
                      <span>Reachable</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="ssh == 'unavailable'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          rounded
                          text
                          v-bind="attrs"
                          v-on="on"
                        >
                          Advanced
                          <div class="blob"></div>
                        </v-btn>
                      </template>
                      <span>Unavailable</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="ssh == 'unauthenticated'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          rounded
                          text
                          v-bind="attrs"
                          v-on="on"
                        >
                          Advanced
                          <div class="blob red"></div>
                        </v-btn>
                      </template>
                      <span>Unauthenticated</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="ssh == 'reachable'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          rounded
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click.native.stop
                          :to="{
                            name: 'Remote',
                            params: { ident: childEntity.ident, remote_type: 'ssh' },
                          }"
                          :disabled="childEntity.type != 'device'"
                        >
                          Advanced
                          <div class="blob blue"></div>
                        </v-btn>
                      </template>
                      <span>Reachable</span>
                    </v-tooltip>



                  </div>
              </div>
            </div>

            <EntityEdit v-bind:entity="childEntity"></EntityEdit>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- </div> -->
      <!-- MODALS -->
      <Password
        v-bind:entity="this.passEntity"
        :passDialog.sync="passDialog"
      ></Password>
      <v-row justify="space-around">
        <v-col cols="auto">
          <v-dialog max-width="600" v-model="createDialog">
            <v-card>
              <v-card-text>
                <EntityCreate v-bind:entity="entity"></EntityCreate>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="createDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import Password from "@/components/entities/Password";
import EntityEdit from "@/components/entities/EntityEdit";
import EntityCreate from "@/components/entities/EntityCreate";

import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  components: {
    Password,
    Breadcrumbs,
    EntityEdit,
    EntityCreate,
  },

  data: function () {
    return {
      refreshInterval: 1000,
      ident: "",
      rootIdent: "",
      entity: {
        children: {},
      },
      passEntity: {},
      passDialog: false,
      createDialog: false,
      ovpn: "unavailable",
      ssh: "unavailable",
      showStatus: false
    };
  },
  watch: {
    "$route.params.ident": function (ident) {
      this.goToIdent(ident);
    },
  },
  created() {
    if (this.$route.params.ident) {
      this.goToIdent(this.$route.params.ident)
    } else {
      this.$router.push({ name: "Entities", params: { ident: this.$store.getters.entityRoot } });
      this.goToIdent(this.$store.getters.entityRoot)
    }
  },
  methods: {
    passEditBox(entity) {
      this.passEntity = {};
      this.passDialog = true;
      this.passEntity = entity;
    },
    hideAccordion() {
      true;
    },
    closeAllPanels() {
      this.openedPanel = [];
    },
    goToRemote(ident) {
      this.$router.push({ name: "Remote", params: { ident: ident } });
    },
    goToError() {
      this.$router.push({ name: "PageNotFound" });
    },
    goToIdent(ident) {
      this.ident = ident;
      this.getInfos(ident);
    },
    async getEntityInfo(ident) {
      const response = await this.$http({
        method: "get",
        url: `/api/entities/${ident}`,
        data: {},
        result: {},
      })
        .then((res) => {
          console.log(res)
          this.$store.commit("hideLoader");
          return res.data;

        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
        return response;
    },
    getstatus(ident) {
        this.ovpn = "unavailable"
        this.ssh = "unavailable"
        this.showStatus = false
        this.$http({
          method: "get",
          url: `/remote/device/` + ident,
          data: {},
          result: {},
        })
          .then((res) => {
            this.$store.commit("hideLoader");

            for (var j = 0; j < res.data.length; j++) {
              if(res.data[j].remote == "ovpn"){
                this.ovpn = res.data[j].status
              }
            }

            for (var k = 0; k < res.data.length; k++) {
              if(res.data[k].remote == "ssh"){
                this.ssh = res.data[k].status
              }
            }
            this.showStatus = true
            return res.data;
          })
          .catch(function (erreur) {
            //On traite ici les erreurs éventuellement survenues
            console.log(erreur);
          });

    },
    async getEntityChildren(ident) {
      const response = await this.$http({
        method: "get",
        url: `/api/entities/${ident}/children`,
        data: {},
        result: {},
      })
        .then((res) => {
          this.$store.commit("hideLoader");
          return res.data;

        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
        return response;
    },
    getInfos(ident){
      this.$store.commit("showLoader");
      Promise.all([this.getEntityInfo(ident), this.getEntityChildren(ident)])
        .then(values => {
          //first return value
          this.entity = values[0];
          //second return value
          this.entity['children'] = values[1];
          this.$store.commit("hideLoader");
        })
        .catch(error => {
          this.$store.commit("hideLoader");
          this.errormsg = error.response.data.message;
        });      
    },
  },
};
</script>

<style>
.entity.active {
  border-left: 4px solid #3cd1c2;
}
.session.requested {
  border-left: 4px solid orange;
}
.session.Closed {
  border-left: 4px solid tomato;
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

* {
  box-sizing: border-box;
}

.blobs-container {
  display: flex;
}

.blobs-container2 {
  display: flex;
  margin-right: 15px;
  float: right;
}

.blob {
  background: rgb(192, 192, 192);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 15px;
  width: 15px;
  transform: scale(1);
  margin-right: -5px;
  margin-left: 4px;
  margin-bottom: 11px;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blob.white {
  background: white;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.orange {
  background: rgba(255, 121, 63, 1);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
  animation: pulse-orange 2s infinite;
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}

.blob.yellow {
  background: rgba(255, 177, 66, 1);
  box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
  animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
}

.blob.blue {
  background: rgba(52, 172, 224, 1);
  box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

.blob.purple {
  background: rgba(142, 68, 173, 1);
  box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
  animation: pulse-purple 2s infinite;
}

@keyframes pulse-purple {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
  }
}

footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
}

.loader {
  border-top: 16px solid blue;
  border-right: 16px solid rgb(0, 225, 255);
  border-bottom: 16px solid red;
  border-left: 16px solid rgb(255, 166, 0);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  float: right;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 

.icon {
  margin-right: 20px;
  margin-left: -30px;
}

.left_margin {
  margin-left: 25px;
}

</style>






