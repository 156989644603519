<template>
  <div class="session">
  <v-container>
    <v-row>
      <v-col>
        <!-- Debut de la carte -->
        <v-card elevation="3">
          <v-card-title>Bienvenue sur la Console Alemca !</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <!-- Debut de la carte -->
        <v-card elevation="3">
          <v-card-title>Status des services</v-card-title>
          <v-card-text>
            Les états de l'infrastructure Alemca sont reporté sur un moniteur Uptime Publique
          </v-card-text>
              <v-card-actions>
      <v-btn
        color="primary"
        depressed
        text
        target="_blank"
        :href="'https://uptime.alemca.com/status/services'"
      >
        Moniteur d'uptime
      </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <!-- Debut de la carte -->
        <v-card elevation="3">
          <v-card-title>Information de connection</v-card-title>
          <v-card-text>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>URL du broker MQTT</v-list-item-title>
                <v-list-item-subtitle>broker.alemca.tech</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Port du broker MQTT (non ssl)</v-list-item-title>
                <v-list-item-subtitle>1883</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Port du broker MQTT (ssl)</v-list-item-title>
                <v-list-item-subtitle>8883</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <!-- Debut de la carte -->
        <v-card elevation="3">
        <v-card-title>Support</v-card-title>
        <v-card-text>
          <div class="text--primary">
          Le support passe par l'envoie d'un email à : ops@rs2d.fr
          </div>
        </v-card-text>
        <v-card-title>Astreinte</v-card-title>
        <v-card-text>
          <div class="text--primary">
        Une astreinte est disponible au numéro suivant : 07.55.61.15.49
          </div>
        </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>




