<template>
      <v-card>
      <v-card-title>
        <span class="text">Create Entity</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col col12>
              <v-text-field
                :rules="entityNameRules"
                label="Name"
                v-model="newEntity.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs6 sm4 md9>
              <v-text-field
                label="Alias"
                v-model="newEntity.alias"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs6 sm4 md6>
              <v-select
                :items="this.$store.getters.defaultVal.entitiesTypes"
                label="Entity type"
                v-model="newEntity.type"
                required
              ></v-select>
            </v-col>
            <v-col xs6 sm4 md6>
              <v-select
                :items="this.$store.getters.defaultVal.entitiesStatus"
                label="Entity status"
                v-model="newEntity.status"
                required
              ></v-select>
            </v-col> </v-row
          ><v-row>
            <v-col xs6 sm4 md12>
              <v-textarea
                clearable
                clear-icon="mdi-close-circle"
                label="comment"
                v-model="newEntity.comment"
              ></v-textarea> </v-col
          ></v-row>
          <v-row>
            <v-col v-if="createLoading">
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular></div
            ></v-col>
          </v-row>
          <v-row v-if="alertShow"
            ><v-col>
              <v-alert dense text col12>
                {{ alertValue }}
              </v-alert></v-col
            >
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="this.createDialog = false">
          Annuler
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit"> Valider </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
export default {
  props: ["entity"],

  data() {
    return {
      dialog: false,
      createLoading: false,
      alertType: "",
      alertValue: "",
      alertShow: false,
      newEntity: {},
      defaultEntity: {
        name: "",
        alias: "",
        type: "",
        status: "initial",
      },
      entityNameRules: [
        (v) => !!v || "Name is required",
        (v) => (v || "").indexOf(" ") < 0 || "No spaces are allowed",
      ],
      apiResponse: {},
    };
  },
  created() {
    this.newEntity = { ...this.defaultEntity };
  },
  methods: {
    submit() {
      this.$store.commit("showLoader");
      this.$data.createLoading = true;

      this.$http({
        method: "post",
        url: "/api/entities/" + this.entity.ident,
        data: this.newEntity,
      })
        .then(
          (result) => {
            this.$data.createLoading = false;
            this.$data.apiResponse = result.data;
            this.entity.children.push(result.data)
            console.log(this.$data.apiResponse);
          }
          //On traite la suite une fois la réponse obtenue

          // $emit('update-entity', data);
        )
        .catch((error) => {
          this.$data.alertValue = error.response.data;
          this.$data.alertShow = true;
          this.$data.createLoading = false;
        });
      this.dialog = false;
    },
  },
};
</script>