<template>
  <v-progress-linear
    v-if="this.$store.state.loadingBarDisplay"
    indeterminate
    top
    color="blue accent-4"
  ></v-progress-linear>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

