import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import Vuex from 'vuex'


import authentication from '@/plugins/authentication';

Vue.config.productionTip = false
Vue.config.devtools = true


//global var to share values accross components
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        loadingBarDisplay: false,
        entity: {
            root: '',
            breadcrumb: {}
        },
        defaultVal: {
            entitiesStatus: [],
            entitiesTypes: [],
            ACLTypes: []
        }
    },
    mutations: {
        showLoader(state) {
            state.loadingBarDisplay = true;
        },
        hideLoader(state) {
            state.loadingBarDisplay = false;
        },
        changeEntityRoot: (state, ident) => {
            state.entity.root = ident;
        },
        updateEntitiesStatus: (state, status) => {
            state.defaultVal.entitiesStatus = status;
        },
        updateEntitiesTypes: (state, types) => {
            state.defaultVal.entitiesTypes = types;
        },
        updateACLTypes: (state, types) => {
            state.defaultVal.ACLTypes = types;
        },

    },
    getters: {
        entityRoot: state => {
            return state.entity.root
        },
        defaultVal: state => {
            return state.defaultVal
        }
    }
})


//authentication with keycloack
Vue.use(authentication)

Vue.$keycloak
    .init({
        onLoad: 'login-required',
        checkLoginIframe: false
    })
    .then(() => {
        // axios.defaults.baseURL = process.env.API_URL;
        // axios.defaults.baseURL = process.env.VUE_APP_API_URL;
        axios.defaults.baseURL = "https://api.alemca.tech"
        axios.defaults.headers.common = { 'Authorization': `Bearer ${Vue.$keycloak.token}` }

        new Vue({
            vuetify,
            router,
            store: store,
            render: h => h(App),
        }).$mount('#app');

        //periodical test for token refresh
        setInterval(() => {
            Vue.$keycloak.updateToken(10).then((refreshed) => {
                if (refreshed) {
                    // console.log('Token refreshed ' + refreshed);
                    Vue.prototype.$http.defaults.headers.common = { 'Authorization': `Bearer ${Vue.$keycloak.token}` }
                    console.log('Token refreshed');

                } else {
                    // console.log('Token not refreshed, valid for ' + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                }
            }).catch(() => {
                console.log('Failed to refresh token');
            });
        }, 10000)
    });

//for API requests
Vue.prototype.$http = axios
