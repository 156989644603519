<template>
  <v-row justify="space-around" class="mb-5">
    <v-col cols="10">
    <v-card outlined color="darken-2" style="max-width: 100%;">
        <v-card-title>
          <span class="text-h5">New session {{newSession.remoteType}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-select
                  :items="['http', 'https', 'tcp', 'udp']"
                  label="Type de connexion"
                  v-model="newSession.type"
                  required
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Port"
                  v-model="newSession.port"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Time to live (s)*"
                  v-model="newSession.ttl"
                  required
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*max ttl: {{max_ttl}}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>


            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  @click="cancel"
                >
                  Annuler
                </v-btn>
              </template>
              <span>Cancel</span>
            </v-tooltip>

            <div style="min-width: 10px;"></div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  Monter
                </v-btn>
              </template>
              <span>Mount session</span>
            </v-tooltip>

        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["newSession", "entity", "auto_start"],
  data() {
    return {
      session: "",
      max_ttl: ""
    };
  },
  created() {
    this.getAcl(this.entity.ident)
    this.AutoStart(this.auto_start)
  },
  methods: {
    AutoStart(auto_start) {
      if(auto_start == "true") {
        this.sleep(500)
        .then(() => this.submit())
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    submit() {
      this.$emit("clicked", "someValue");
    },
    cancel(){
      this.$emit("cancelComponent", "")
    },
    getAcl(ident) {
      this.$http({
        method: "get",
        url: `/api/entities/${ident}/acl/remote`,
        data: {},
      })
        .then((res) => {
          console.log(res)
          console.log(res.data.value.max_ttl)

          this.max_ttl = res.data.value.max_ttl
        })
        .catch(function (erreur) {
          console.log(erreur);
        });
    },
  },
};
</script>