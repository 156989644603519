<template>
  <div>
    <nav>
      <v-app-bar app>
        <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
        <router-link
          :to="{ name: 'Home' }"
          style="text-decoration: none; color: inherit"
        >
          <v-toolbar-title class="text-uppercase pr-10">
            <span class="font-weight-light">Alemca</span>
            <span>Console</span>
          </v-toolbar-title>
        </router-link>
        <router-link :to="{ name: 'Home' }">
          <v-btn depressed>
            <v-icon>mdi-home </v-icon>
            Accueil
          </v-btn>
        </router-link>
        <router-link :to="{ name: 'Entities' }">
          <v-btn depressed>
            <v-icon>mdi-devices </v-icon>
            Mon inventaire
          </v-btn>
        </router-link>
        <v-spacer></v-spacer>
        <router-link :to="{ name: 'User' }">
          <v-btn depressed>
            <v-icon> mdi-account </v-icon>
            {{ this.$keycloak.tokenParsed.name }}
          </v-btn>
        </router-link>

        <v-btn color="primary" @click="logout">
          <span>Deconnexion</span>
          <v-icon> mdi-arrow-right-bold-box-outline </v-icon>
        </v-btn>

      </v-app-bar>




    </nav>

    <v-footer app padless>

        <div class="footer">{{ new Date().getFullYear() }} — <strong>Alemca</strong> a RS2D Solution - V1.3</div>

    </v-footer>

<!--
    <v-navigation-drawer app permanent expand-on-hover>
      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item link :to="{ name: 'Home' }">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'Entities' }">
          <v-list-item-icon>
            <v-icon>mdi-devices</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Entities</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
-->
  </div>
</template>

<script>
// import LoadingBar from "@/components/LoadingBar";

export default {
  // components: { LoadingBar },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    logout() {
      //disconnect keycloak user
      this.$keycloak.logout();
    },
  },
};
</script>

<style>

.footer{
  text-align: center;
  margin: auto;
}

</style>