var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"remote"},[_c('v-container',{},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('Breadcrumbs')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mx-3 text-uppercase text-h4"},[_vm._v("Current Entity")])])],1),_c('v-card',{staticClass:"mt-4 mb-3",attrs:{"height":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('div',{staticClass:"left_margin_remote"}),_c('div',{staticClass:"icon"},[(_vm.entity.type=='anchor')?_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" mdi-folder ")]):_vm._e(),(_vm.entity.type=='root_asset')?_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" mdi-folder-open ")]):_vm._e(),(_vm.entity.type=='client_root')?_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" mdi-folder-account-outline ")]):_vm._e(),(_vm.entity.type=='device')?_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" mdi-devices ")]):_vm._e(),(_vm.entity.type=='user')?_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" mdi-account ")]):_vm._e(),(_vm.entity.type=='asset')?_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" mdi-folder-open-outline ")]):_vm._e(),(_vm.entity.type=='app')?_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" mdi-apps ")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5"},[(_vm.entity.alias)?_c('div',[_c('span',{},[_vm._v(_vm._s(_vm.entity.alias))]),_c('span',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(_vm.entity.name))])]):_c('div',[_vm._v(" "+_vm._s(_vm.entity.name)+" ")])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.entity.type)+" - "+_vm._s(_vm.entity.ident))])],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-end mb-6",attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"dark":"","color":"primary","to":{ name: 'Entities', params: { ident: _vm.entity.ident } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-devices ")])],1)]}}])},[_c('span',[_vm._v("View entity")])]),_c('div',{staticClass:"margin_right_remote"})],1)],1),_c('v-spacer')],1),_c('v-card',{staticClass:"mt-4 mb-3",attrs:{"height":"100%"}},[(_vm.showStatus)?_c('v-row',[_c('div',{staticClass:"session",attrs:{"xs12":"","sm12":"","md12":""}},[_c('h1',{staticClass:"subheading grey--text"},[_vm._v("Sessions")])]),_c('v-spacer'),(_vm.ovpn == 'unavailable')?_c('div',{staticClass:"sessionMenu"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Basic "),_c('div',{staticClass:"blob"})])]}}],null,false,937855050)},[_c('span',[_vm._v("Unavailable")])])],1):_vm._e(),(_vm.ovpn == 'reachable')?_c('div',{staticClass:"sessionMenu"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){return _vm.createSessionMenu('ovpn')}}},'v-btn',attrs,false),on),[_vm._v(" Basic "),_c('div',{staticClass:"blob blue"})])]}}],null,false,279326997)},[_c('span',[_vm._v("Reachable")])])],1):_vm._e(),_c('v-spacer'),(_vm.ssh == 'unavailable')?_c('div',{staticClass:"sessionMenu"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Advanced "),_c('div',{staticClass:"blob"})])]}}],null,false,865110830)},[_c('span',[_vm._v("Unavailable")])])],1):_vm._e(),(_vm.ssh == 'unauthenticated')?_c('div',{staticClass:"sessionMenu"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Advanced "),_c('div',{staticClass:"blob red"})])]}}],null,false,284837821)},[_c('span',[_vm._v("Unauthenticated")])])],1):_vm._e(),(_vm.ssh == 'reachable')?_c('div',{staticClass:"sessionMenu"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){return _vm.createSessionMenu('ssh')}}},'v-btn',attrs,false),on),[_vm._v(" Advanced "),_c('div',{staticClass:"blob blue"})])]}}],null,false,2293394782)},[_c('span',[_vm._v("Reachable")])])],1):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"sessionMenu",staticStyle:{"margin-right":"34px"},attrs:{"xs2":"","sm4":"","md12":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"primary"},on:{"click":function($event){return _vm.getSessions(_vm.entity.ident)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cached ")])],1)]}}],null,false,999794290)},[_c('span',[_vm._v("Refresh list")])])],1)],1):_vm._e(),(_vm.createSessionOvpn)?_c('v-row',{attrs:{"row":""}},[_c('v-col',{attrs:{"xs2":"","sm4":"","md12":""}},[_c('RemoteCreateOvpn',{attrs:{"newSession":this.newSession,"entity":this.entity,"dialog":this.createSessionOvpn,"auto_start":this.auto_start},on:{"clicked":_vm.createSessionSubmit,"cancelComponent":_vm.closeComponent}})],1)],1):_vm._e(),_c('v-row',{attrs:{"row":""}}),(_vm.createSessionSsh)?_c('v-row',{attrs:{"row":""}},[_c('v-col',{attrs:{"xs2":"","sm4":"","md12":""}},[_c('RemoteCreateSsh',{attrs:{"newSession":this.newSession,"entity":this.entity,"dialog":this.createSessionSsh,"auto_start":this.auto_start},on:{"clicked":_vm.createSessionSubmit,"cancelComponent":_vm.closeComponent}})],1)],1):_vm._e(),_c('v-row',{attrs:{"row":""}},[_c('v-col',{attrs:{"xs2":"","sm4":"","md12":""}},[_c('RemoteList',{attrs:{"sessions":_vm.sessions},on:{"updateParent":_vm.updateParent}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }