<template>
 <div class="breadcrumbs">  
      <v-breadcrumbs
        :items="items"
        divider="/"
        large
      ></v-breadcrumbs>
    </div>
</template> 
<script>
export default {
  props: ["entity"],

  data() {
    return {
      items: ["U-CONNECT ROOT"],
    };
  },
  created() {
    this.updateBreadcrumb(this.$route.params.ident);
    // this.currentEntity = { ...this.entity };
    // this.editEntity = this.entity;
  },
    watch: {
    "$route.params.ident": function (ident) {
      this.updateBreadcrumb(ident);
    },
  },
  methods: {
    updateBreadcrumb(ident) {
      this.items = []
      this.$http({
        method: "get",
        url: `/api/entities/${ident}/parents`,
      })
        .then((result) => {
          result.data.forEach((entity) => {
              entity.name = (entity.name == "") ? entity.alias : entity.name; //manage root_asset case
              let val = {
              text: entity.name.toUpperCase(),
              link: true,
              exact: true,
              disabled: false,
              to: { 
                name: 'Entities',
                params: {
                  ident: entity.ident
                }
              }
            }
            this.$data.items.push(val);   
          }          
          );
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
    },

  },
};
</script>

<style>

.breadcrumbs{
  height: 50px;
}

</style>