<template>
  <v-layout pa-3>
    <v-card-text>
      <v-row align="center">
        <v-row justify="end" align="center">
          <v-col>
            <v-dialog v-model="show" max-width="390">
              <v-card>
                <v-card-title class="headline">Manage password</v-card-title>

                <v-card-text>
                  <v-row>
                    <v-btn
                      block
                      class="mt-3"
                      @click="getPassword(entity.ident)"
                    >
                      Retrieve
                    </v-btn>
                    <v-row v-if="!passTextHidden">
                      <v-col class="ma-0 pa-0 mt-3">
                        <v-alert text block dense>
                          <v-btn
                            outlined
                            small
                            @click.stop.prevent="copyPassword"
                          >
                            Copy
                          </v-btn>
                          <input
                            v-on:focus="$event.target.select()"
                            ref="password"
                            readonly
                            width="100%"
                            :value="passText"
                          />
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-switch
                      v-model="passEdit"
                      :label="`Edit Password (irreversible)`"
                    ></v-switch>
                    <v-btn
                      block
                      class="mt-3"
                      @click="resetPassword(entity.ident)"
                      :disabled="!passEdit"
                    >
                      Create/Reset
                    </v-btn>

                    <v-btn
                      block
                      class="mt-3"
                      @click="deletePassword(entity.ident)"
                      :disabled="!passEdit"
                    >
                      delete
                    </v-btn>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    block
                    class="mb-3"
                    v-on:click="hideDialog"
                  >
                    Back
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>
  </v-layout>
</template>





<script>
// import AttributesEdit from "@/components/AttributesEdit";

export default {
  props: ["entity", "passDialog"],
  //   components: { AttributesEdit },

  data: function () {
    return {
      passTextHidden: true,
      passText: "",
      passEdit: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.passDialog;
      },
      set() {
        this.hideDialog();
      },
    },
  },
  methods: {
    resetDialog() {
     this.$data.passTextHidden = true;
     this.$data.passText = '';
     this.$data.passEdit = false;
    },
    hideDialog() {
      this.$emit('update:passDialog', false);
      this.resetDialog();
    },
    getPassword(ident) {
      this.$store.commit("showLoader");

      this.$http({
        method: "get",
        url: `/api/entities/${ident}/password`,
        data: {},
      })
        .then((result) => {
          console.log(result);
          this.$data.passText =
            result.data.password == null ? "No password" : result.data.password;
          this.$data.passTextHidden = false;
          this.$store.commit("hideLoader");
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
    },
    resetPassword(ident) {
      this.$store.commit("showLoader");

      this.$http({
        method: "post",
        url: `/api/entities/${ident}/password`,
        data: {},
      })
        .then((result) => {
          this.$data.passText =
            result.data.password == null ? "No password" : result.data.password;
          this.$data.passTextHidden = false;
          this.$store.commit("hideLoader");
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
    },
    deletePassword(ident) {
      this.$store.commit("showLoader");

      this.$http({
        method: "delete",
        url: `/api/entities/${ident}/password`,
        data: {},
      })
        .then((result) => {
          this.$data.passText =
            result.data.password == null ? "No password" : result.data.password;
          this.$data.passTextHidden = false;
          this.$store.commit("hideLoader");
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
    },
    copyPassword() {
      //copy password to clipboard
      this.$refs.password.focus();
      document.execCommand("copy");
    },
  },
};
</script>







