<template>
  <div class="dashboard">
    <v-card flat v-for="(session, index) in sessions" :key="session" height="100%">
      <v-layout wrap
      v-bind:class="sessionColor(session)"
      >
      <!-- {{session}} -->

        <v-flex xs12 md5>
          <div class="caption grey--text">Session</div>
          <div :key="session.id">
            <a
              v-if="session.status == 'Created' && session.service != 'tcp'"
              v-bind:href="session.url"
              target="_blank" class="input_purp"
              >{{ session.session_ident }}
              <v-icon small> mdi-open-in-new </v-icon>
            </a>
            <div
              v-else-if="session.status == 'Created' && session.service == 'tcp'"
              target="_blank"
              >
              <input class="input_tcp"
                v-on:focus="$event.target.select()"
                ref="url"
                readonly
                width="100%"
                :value="session.url"
              />
              <v-btn icon @click.stop.prevent="copyUrl(index)"><v-icon small> mdi-content-copy </v-icon></v-btn>
            </div>

            <a v-else><span class="input_purp">{{ session.session_ident }}</span></a>
          </div>
        </v-flex>
        <v-flex xs6 sm4 md3>
          <div class="caption grey--text">Creation Time</div>
          <div>
            {{ session.creation_time.format("DD/MM/YYYY HH:mm:ss") }}
          </div>
        </v-flex>
        <v-flex xs6 sm2 md3>
          <div class="caption grey--text">End Time</div>
          <div>{{ session.end_time.format("DD/MM/YYYY HH:mm:ss") }}</div>
        </v-flex>
        <v-flex xs6 sm2 md3>
          <div class="caption grey--text">Type/Port</div>
          <div v-if="session.value.ovpn_service_port">
            {{ session.service }}/{{ session.value.ovpn_service_port }}
          </div>
          <div v-if="session.value.ssh_service_port">
            {{ session.service }}/{{ session.value.ssh_service_ip }}:{{ session.value.ssh_service_port }}
          </div>
        </v-flex>
        <v-flex xs2 sm2 md2>
          <div class="caption grey--text">Status</div>
          <div>{{ session.status }}</div>
        </v-flex>
        <v-flex xs2 sm2 md3>
          <div class="caption grey--text">User</div>
          <div>{{ session.user }}</div>
        </v-flex>
        <v-flex xs2 sm2 md3>
          <div class="caption grey--text">Remote type</div>
          <div>{{ session.remote }}</div>
        </v-flex>

        <v-flex xs1 sm1 md1 class="btn-refresh">

          <input type="hidden" id="link" :value="link">

          <v-btn-toggle
            dense
            dark
          >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="repeat(index)"
                  >
                    <v-icon dark> mdi-repeat-variant </v-icon>
                  </v-btn>
                </template>
                <span>Repeat session</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="black"
                    v-bind="attrs"
                    v-on="on"
                    @click="repeat2(index)"
                  >
                    <v-icon dark> mdi-link </v-icon>
                  </v-btn>
                </template>
                <span>Create link</span>
              </v-tooltip>

          </v-btn-toggle>

        </v-flex>

      </v-layout>


    </v-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["sessions"],
  data: function () {
    return {
      newSession: {},
      remite: "",
      link: ""
    };
  },
  methods: {
    sessionColor(session) {
        let sessionClasse = '';
        if (session.end_time < moment()) {
          sessionClasse = 'pa-3 session Closed'
          session.status = 'Closed'
        }else{
          sessionClasse = 'pa-3 session ' + session.status
        }
        
        return sessionClasse //this.content['cravings'] ? 'fa-checkbox-marked' : 'fa-checkbox-blank-outline';
    },
    repeat(index) {
      console.log(index)
      console.log(this.sessions[index])
      this.newSession.ident = this.sessions[index].session_ident;
      if (this.sessions[index].value.ovpn_service_port) {
        this.newSession.port = this.sessions[index].value.ovpn_service_port;
      }
      if (this.sessions[index].value.ssh_service_port) {
        this.newSession.port = this.sessions[index].value.ssh_service_port;
      }
      if (this.sessions[index].value.ssh_service_ip) {
        this.newSession.ip = this.sessions[index].value.ssh_service_ip;
      }
      this.newSession.type = this.sessions[index].service;
      this.newSession.ttl = this.sessions[index].ttl;

      // this.newSession.ttl = this.session.session_ident;
      this.newSession.remoteType = this.sessions[index].remote;
      if (this.sessions[index].remote == "ovpn") {
        this.remote = "ovpn";
      }
      if (this.sessions[index].remote == "ssh") {
        this.remote = "ssh";
      }
      this.$emit("updateParent", this.newSession, this.remote)
    },
    repeat2(index) {
      console.log(index)
      console.log(this.sessions[index])
      
      let url
      let base_url

      // base_url = process.env.VUE_APP_CONSOLE_URL + "/remote/"
      // base_url = "https://console.Alemca.io/remote/"
      // base_url = "http://172.17.0.2:8080/remote/"
      base_url = "https://console.alemca.tech/remote/"

      if (this.sessions[index].remote == "ssh") {
        url = base_url + this.sessions[index].device_ident + "?remote=" + this.sessions[index].remote + "&service=" + this.sessions[index].service + "&port=" + this.sessions[index].value.ssh_service_port + "&ttl=" + this.sessions[index].ttl + "&ip=" + this.sessions[index].value.ssh_service_ip
      }

      if (this.sessions[index].remote == "ovpn") {
        url = base_url + this.sessions[index].device_ident + "?remote=" + this.sessions[index].remote + "&service=" + this.sessions[index].service + "&port=" + this.sessions[index].value.ovpn_service_port + "&ttl=" + this.sessions[index].ttl
      }
      
      this.link = url
      console.log(url)
      this.sleep(200)
        .then(() => this.copyTestingCode())
      // window.open(url, '_blank').focus();
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    copyUrl(index) {
      //copy password to clipboard
      console.log(index)
      this.$refs.url[index].focus();
      document.execCommand("copy");
    },
    copyLink(index) {
      //copy password to clipboard
      console.log(index)
      console.log(this.$refs.link[index])
      this.$refs.link[index].focus();
      document.execCommand("copy");
    },
    copyTestingCode () {
          let testingCodeToCopy = document.querySelector('#link')
          console.log("testingCodeToCopy")
          console.log(testingCodeToCopy)
          testingCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
          testingCodeToCopy.select()

          try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            alert('Url was copied ' + msg);
          } catch (err) {
            alert('Oops, unable to copy');
          }

          /* unselect the range */
          testingCodeToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
        }
}


};
</script>

<style>

.input_purp {
  color: #503386;
}

.input_tcp {
  color: #503386;
  width: 280px;
}

.btn-refresh {
  margin-top: -20px;
  margin-left: -30px;
}

.session.Created {
  border-left: 4px solid #3cd1c2;
  border-top: 4px solid #3cd1c2;
}
.session.requested {
  border-left: 4px solid orange;
  border-top: 1px solid rgb(192, 192, 192)
}
.session.Processing {
  border-left: 4px solid orange;
  border-top: 1px solid rgb(192, 192, 192)
}
.session.Closed {
  border-left: 4px solid rgb(255, 38, 0);
  border-top: 1px solid rgb(192, 192, 192)
}
.session.Error {
  border-left: 4px solid rgb(160, 0, 0);
  border-top: 1px solid rgb(192, 192, 192)
}
</style>