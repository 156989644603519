<template>
  <div id="entity-details-edit">
    
    <v-row style="margin-top: 0px;">

      <v-tooltip bottom v-if="!editing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            rounded
            text
            v-on:click="editing = !editing"
            v-bind="attrs"
            v-on="on"
          >
            Edit
            <v-icon> mdi-pencil-outline </v-icon>
          </v-btn>
        </template>
        <span>Edit entity</span>
      </v-tooltip>

      <v-tooltip bottom v-if="editing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            rounded
            text
            v-on:click="commitChange(entity)"
            v-bind="attrs"
            v-on="on"
          >
            Save
            <v-icon> mdi-content-save </v-icon>
          </v-btn>
        </template>
        <span>Save changes</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined 
            rounded 
            text 
            @click="getEntity(entity.ident)"
            v-bind="attrs"
            v-on="on"
          >
            Attributes
            <v-icon> mdi-information-outline </v-icon>
          </v-btn>
        </template>
        <span>Manage attributes</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined 
            rounded 
            text 
            @click="aclDialog = !aclDialog"
            v-bind="attrs"
            v-on="on"
          >
            ACL
            <v-icon> mdi-shield-check-outline </v-icon>
          </v-btn>
        </template>
        <span>Manage Acl</span>
      </v-tooltip>

    </v-row>

    <v-row>
      <v-col cols="6">
        <div class="caption grey--text">Name</div>
        <div>
          {{ entity.name }}
        </div>
      </v-col>
      <v-col cols="6">
        <div class="caption grey--text">Alias</div>
        <v-text-field
          v-model="entity.alias"
          :hide-details="true"
          dense
          single-line
          v-if="editing"
        ></v-text-field>
        <span v-else>{{ entity.alias }}</span>
      </v-col>
      <v-col cols="6">
        <div class="caption grey--text">Status</div>
        <v-select
          v-model="entity.status"
          :items="this.$store.getters.defaultVal.entitiesStatus"
          :hide-details="true"
          dense
          single-line
          v-if="editing"
        ></v-select>
        <span v-else>{{ entity.status }}</span>
      </v-col>
      <v-col cols="6">
        <div class="caption grey--text">Type</div>
        <div>
          {{ entity.type }}
        </div>
      </v-col>


      <v-col cols="6">
        <div class="caption grey--text">Ident</div>
        <input style="min-width: 310px;"
            v-on:focus="$event.target.select()"
            ref="ident"
            readonly
            width="100%"
            :value="entity.ident"
          />
          <v-btn icon @click.stop.prevent="copyIdent"><v-icon> mdi-content-copy </v-icon></v-btn>
      </v-col>


      <v-col cols="6">
        <div class="caption grey--text">Parent Ident</div>
        <div v-if="entity.ident != this.$keycloak.tokenParsed.entity_root">
          {{ entity.parent_ident }}
        </div>
        <div v-else>root</div>
      </v-col>
      <v-col cols="6">
        <div class="caption grey--text">Path</div>
        {{ entity.path }}
      </v-col>
      <v-col cols="6">

        
        <div class="caption grey--text">Password</div>
        <div v-if="showPass==true">
          <v-btn icon @click="showPassword(entity)"><v-icon> mdi-eye-off-outline </v-icon></v-btn>
          <span v-if="!password.data.password">No password</span>
          <input v-if="password.data.password"
            v-on:focus="$event.target.select()"
            ref="password"
            readonly
            width="100%"
            :value="password.data.password"
          />
          <v-btn v-if="password.data.password" icon @click.stop.prevent="copyPassword"><v-icon> mdi-content-copy </v-icon></v-btn>
          

          <v-btn v-if="!password.data.password" icon @click="addPassword(entity)"><v-icon> mdi-plus </v-icon></v-btn>

          
          <v-dialog v-if="password.data.password" v-model="new_dialog" width="500">

          
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="generateCode(), new_dialog = true"
            >
              <v-icon> mdi-lock-reset </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Generate new password
            </v-card-title>
            <v-card-text style="text-align: center; margin-top: 20px;">
              <v-card style="text-align: center;">
                Attention cette action est irrévocable<br>
                Veuillez saisir le code ci dessous pour valider votre action
                
                <br><br>{{code}}
                <v-text-field style="max-width: 100px;margin: auto; margin-top: 15px; margin-bottom: -15px;"
                class="centered-input text--darken-3 mt-3" 
                solo
                label="code"
                v-model="clientCode"
                auto-grow
                >
              </v-text-field>
              <p v-if="error">code incorrect</p>
                <v-btn text @click="validPassword(entity)">Valider</v-btn>
                <v-btn text @click="new_dialog = false">Annuler</v-btn>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>

        </div>
        <div v-if="showPass==false">
          <v-btn icon @click="showPassword(entity)"><v-icon> mdi-eye-outline </v-icon></v-btn>
        </div>

      </v-col>
      <v-col cols="12">
        <div class="caption grey--text">Comment</div>
        <v-text-field
          v-model="entity.comment"
          :hide-details="true"
          dense
          single-line
          v-if="editing"
        ></v-text-field>
        <span v-else>{{ entity.comment }}</span>
      </v-col>
    </v-row>

    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog max-width="600" v-model="attributeDialog">
          <v-card>
            <v-toolbar>Attributes</v-toolbar>
            <v-card-text>
              <AttributesEdit :key="entity_attr" v-bind:entity="entity_attr"></AttributesEdit>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="attributeDialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog max-width="900" v-model="aclDialog">
          <v-card>
            <v-toolbar>ACL</v-toolbar>
            <v-card-text>
              <AclEdit v-bind:entity="entity"></AclEdit>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="aclDialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>





<script>
// import AttributesEdit from "@/components/entities/AttributesEdit";
import AttributesEdit from "@/components/entities/AttributesEdit";
import AclEdit from "@/components/entities/AclEdit";

export default {
  props: ["entity"],
  components: { AttributesEdit, AclEdit },

  data: function () {
    return {
      attributeDialog: false,
      aclDialog: false,
      editing: false,
      showPass: false,
      password: "",
      code: "",
      clientCode: "",
      new_dialog: false,
      error: false, 
      entity_attr: {}
    };
  },
  created() {},
  watch: {},
  methods: {
    getEntity(ident){
      this.$http({
          method: "get",
          url: `/api/entities/${ident}`,
          data: {},
        })
          .then((result) => {
            this.entity_attr = result.data
            this.attributeDialog = !this.attributeDialog
          })
          .catch(function (erreur) {
            //On traite ici les erreurs éventuellement survenues
            console.log(erreur);
          });
    },
    addPassword(entity){
      this.$http({
          method: "post",
          url: `/api/entities/${entity.ident}/password`,
          data: {},
        })
          .then((result) => {
            this.$data.passText =
              result.data.password == null ? "No password" : result.data.password;
            this.$data.passTextHidden = false;
            this.$store.commit("hideLoader");
            this.new_dialog = false
            this.getPass(entity)
            this.error = false
          })
          .catch(function (erreur) {
            //On traite ici les erreurs éventuellement survenues
            console.log(erreur);
          });
    },
    getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min +1)) + min;
    },
    generateCode(){
      this.code = this.getRandomIntInclusive('1000', '9999')
    },
    //edit shown entity
    validPassword(entity){

      if(this.code == this.clientCode){
        this.$store.commit("showLoader");

        this.addPassword(entity)


      }else{
        this.error = true
      }
      // this.code = this.getRandomIntInclusive('1000', '9999'),
      this.clientCode = ""
    },
    showPassword(entity) {
      if (this.showPass){
        this.showPass = false
      }else{
        this.getPass(entity)
        this.showPass = true
      }
    },
    copyPassword() {
      //copy password to clipboard
      this.$refs.password.focus();
      document.execCommand("copy");
    },
    copyIdent() {
      //copy password to clipboard
      this.$refs.ident.focus();
      document.execCommand("copy");
    },
    deleteEntity(entity) {
      this.$http({
        method: "delete",
        url: "/api/entities/" + entity.ident,
      })
        .then((result) => {
          //On traite la suite une fois la réponse obtenue
          
          this.$data.editing = false;
          console.log(result)
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
    },
    commitChange(entity) {
      this.$store.commit("showLoader");

      //prepare commiting to API

      if (entity.status == "deleted"){
        this.deleteEntity(entity)
      }else{
        let data = {};
        data = {
          alias: entity.alias,
          status: entity.status,
        };
        
        if (entity.comment !== null) {
          data.comment = entity.comment;
        }
  
        this.$http({
          method: "patch",
          url: "/api/entities/" + this.entity.ident,
          data: data,
        })
          .then((result) => {
            //On traite la suite une fois la réponse obtenue
            this.$data.editing = false;
            data = result.data;
            console.log(data);
          })
          .catch(function (erreur) {
            //On traite ici les erreurs éventuellement survenues
            console.log(erreur);
          });
        //   }
        //refresh entity info
        this.$store.commit("hideLoader");
      }

    },
    getPass(entity) {
      this.$store.commit("showLoader");
      console.log(entity)
      //prepare commiting to API

      this.$http({
        method: "get",
        url: "/api/entities/" + this.entity.ident + "/password"
      })
        .then((result) => {
          //On traite la suite une fois la réponse obtenue
          this.password=result
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
      //   }
      //refresh entity info
      this.$store.commit("hideLoader");
    }
  },
};
</script>

<style>

.centered-input input {
  text-align: center
}

</style>





