import Vue from 'vue'
import Keycloak from 'keycloak-js'

// const options = {
//   url: 'https://auth.prod.uconnect.rs2d.tech/',
//   realm: 'Alemca',
//   clientId: 'cli',
//   secret: "e9bd6cb5-8d59-43bf-a019-959a87edaed4"
      
// }

const options = {
  "realm": "alemca",
  "url": "https://auth.alemca.tech/",
  "ssl-required": "external",
  "resource": "ucr-webapp",
  "clientId": 'ucr-webapp',
  "verify-token-audience": true,
  "credentials": {
    "secret": "5yiZPwpUJjNV1VwzaWNXocQcSQ0SAx4Y"
  },
  "confidential-port": 0,
  "policy-enforcer": {}
}

const _keycloak = Keycloak(options)

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak
  }
}

Plugin.install = Vue => {
  Vue.$keycloak = _keycloak
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
