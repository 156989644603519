  <template>
  <div id="entity-acls-edit">
    <v-row>
      <v-col cols="12">
        <v-btn
            class="btnJson"
            outlined
            rounded
            text
            @click="changeJson()"
          >
          <v-icon> mdi-code-json </v-icon>
        </v-btn>
        <v-card
          v-for="(acl_unit, index) in acl"
          :key="acl_unit.type"
          class="mb-2 mt-2 pa-4"
        >
          <div v-if="editing_index == index">
            <div v-if="json == false">
              <div v-if="acl_unit.type=='mqtt'">
                <a class="font-weight-medium grey--text">Name : </a>
                <v-text-field
                  v-model="current_acl_wizard.content.name"
                  :hide-details="true" style="margin-top: -6px;"
                  dense
                  single-line
                ></v-text-field>
                
                <!-- server_list -->
                <a class="font-weight-medium grey--text">Server list : </a>
                <div v-for="i in current_acl_wizard.content.value.server_list.length" v-bind:key="'A' + i">
                  <v-text-field
                    v-model="current_acl_wizard.content.value.server_list[i-1]"
                    :hide-details="true"
                    dense
                    single-line
                  ></v-text-field>

                </div>

                <v-btn class="btnaddless" x-small icon @click="incrementLength(true, 'server_list', 'edit')">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
                <v-btn class="btnaddless" x-small icon @click="incrementLength(false, 'server_list', 'edit')">
                  <v-icon> mdi-minus </v-icon>
                </v-btn>
                <br>

                <!-- broker_pub -->
                <a class="font-weight-medium grey--text">Broker pub : </a>
                <div v-for="j in current_acl_wizard.content.value.broker_pub.length" v-bind:key="'B' + j">
                  <div class="bpub">
                    <p class="" v-if="'pattern' in current_acl_wizard.content.value.broker_pub[j-1]">
                      <a  class="font-weight-medium grey--text">pattern : </a>
                      <v-text-field
                        v-model="current_acl_wizard.content.value.broker_pub[j-1].pattern"
                        :hide-details="true" style="margin-top: -6px;"
                        dense
                        single-line
                      ></v-text-field>
                    </p>
                    <p v-if="'max_qos' in current_acl_wizard.content.value.broker_pub[j-1]">
                      <a class="font-weight-medium grey--text">max qso : </a>
                      <v-text-field
                        v-model="current_acl_wizard.content.value.broker_pub[j-1].max_qos"
                        :hide-details="true" style="margin-top: -6px;"
                        dense
                        single-line
                      ></v-text-field>
                    </p>
                    <p v-if="'max_payload_size' in current_acl_wizard.content.value.broker_pub[j-1]">
                      <a class="font-weight-medium grey--text">max payload size : </a>
                      <v-text-field
                        v-model="current_acl_wizard.content.value.broker_pub[j-1].max_payload_size"
                        :hide-details="true" style="margin-top: -6px;"
                        dense
                        single-line
                      ></v-text-field>
                    </p>
                    <p v-if="'allowed_retain' in current_acl_wizard.content.value.broker_pub[j-1]">
                      <a class="font-weight-medium grey--text">allowed retain : </a>
                      <v-text-field
                        v-model="current_acl_wizard.content.value.broker_pub[j-1].allowed_retain"
                        :hide-details="true" style="margin-top: -6px;"
                        dense
                        single-line
                      ></v-text-field>
                    </p>
                    <div v-if="'modifiers' in current_acl_wizard.content.value.broker_pub[j-1]">
                      <a class="font-weight-medium grey--text">modifiers : </a>
                      <p v-if="'topic' in current_acl_wizard.content.value.broker_pub[j-1].modifiers">
                        <a style="margin-left: 20px;" class="font-weight-medium grey--text">topic : </a>
                        <v-text-field
                          v-model="current_acl_wizard.content.value.broker_pub[j-1].modifiers.topic"
                          :hide-details="true" style="margin-top: -6px;"
                          dense
                          single-line
                        ></v-text-field>
                      </p>
                      <p v-if="'payload' in current_acl_wizard.content.value.broker_pub[j-1].modifiers">
                        <a style="margin-left: 20px;" class="font-weight-medium grey--text">payload : </a>
                        <v-text-field
                          v-model="current_acl_wizard.content.value.broker_pub[j-1].modifiers.payload"
                          :hide-details="true" style="margin-top: -6px;"
                          dense
                          single-line
                        ></v-text-field>
                      </p>
                      <p v-if="'qos' in current_acl_wizard.content.value.broker_pub[j-1].modifiers">
                        <a style="margin-left: 20px;" class="font-weight-medium grey--text">qos : </a>
                        <v-text-field
                          v-model="current_acl_wizard.content.value.broker_pub[j-1].modifiers.qos"
                          :hide-details="true" style="margin-top: -6px;"
                          dense
                          single-line
                        ></v-text-field>
                      </p>
                      <p v-if="'retain' in current_acl_wizard.content.value.broker_pub[j-1].modifiers">
                        <a style="margin-left: 20px;" class="font-weight-medium grey--text">retain : </a>
                        <v-text-field
                          v-model="current_acl_wizard.content.value.broker_pub[j-1].modifiers.retain"
                          :hide-details="true" style="margin-top: -6px;"
                          dense
                          single-line
                        ></v-text-field>
                      </p>
                      <p v-if="'mountpoint' in current_acl_wizard.content.value.broker_pub[j-1].modifiers">
                        <a style="margin-left: 20px;" class="font-weight-medium grey--text">mountpoint : </a>
                        <v-text-field
                          v-model="current_acl_wizard.content.value.broker_pub[j-1].modifiers.mountpoint"
                          :hide-details="true" style="margin-top: -6px;"
                          dense
                          single-line
                        ></v-text-field>
                      </p>
                    </div>
                  </div>
                </div>


                <v-btn class="btnaddless" x-small icon @click="add_broker('pub')">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>

                <v-btn class="btnaddless" x-small icon @click="incrementLength(false, 'bpub', 'edit')">
                  <v-icon> mdi-minus </v-icon>
                </v-btn>
                <br>

                <div v-if="show_add_pub">

                  <input class="input-checkbox" type="checkbox" id="max_qos" value="max_qos" v-model="add_broker_object.max_qos">
                  <label for="max_qos">max_qos</label>
                  <br>
                  <input class="input-checkbox" type="checkbox" id="max_payload_size" value="max_payload_size" v-model="add_broker_object.max_payload_size">
                  <label for="max_payload_size">max_payload_size</label>
                  <br>
                  <input class="input-checkbox" type="checkbox" id="allowed_retain" value="allowed_retain" v-model="add_broker_object.allowed_retain">
                  <label for="allowed_retain">allowed_retain</label>
                  <br>
                  <input class="input-checkbox" type="checkbox" id="modifiers" value="modifiers" v-model="add_broker_object.modifiers" @click="show_pub_modifier()">
                  <label for="modifiers">modifiers</label>
                  <br>
                  <div class="modifiers" v-if="show_pub_modifiers">
                    <input class="input-checkbox" type="checkbox" id="modifiers_topic" value="modifiers_topic" v-model="add_broker_object.modifiers_topic">
                    <label for="modifiers_topic">modifiers_topic</label>
                    <br>
                    <input class="input-checkbox" type="checkbox" id="modifiers_payload" value="modifiers_payload" v-model="add_broker_object.modifiers_payload">
                    <label for="modifiers_payload">modifiers_payload</label>
                    <br>
                    <input class="input-checkbox" type="checkbox" id="modifiers_qos" value="modifiers_qos" v-model="add_broker_object.modifiers_qos">
                    <label for="modifiers_qos">modifiers_qos</label>
                    <br>
                    <input class="input-checkbox" type="checkbox" id="modifiers_retain" value="modifiers_retain" v-model="add_broker_object.modifiers_retain">
                    <label for="modifiers_retain">modifiers_retain</label>
                    <br>
                    <input class="input-checkbox" type="checkbox" id="modifiers_mountpoint" value="modifiers_mountpoint" v-model="add_broker_object.modifiers_mountpoint">
                    <label for="modifiers_mountpoint">modifiers_mountpoint</label>
                    <br>
                  </div>

                  <v-btn class="btnaddless" x-small text @click="incrementLength(true, 'bpub', 'edit'), add_broker_false(), hide_pub_modifier()"  >
                    OK
                  </v-btn>
                  <v-btn class="btnaddless" x-small text @click="add_broker_false(), hide_pub_modifier()">
                    cancel
                  </v-btn>
                  <br>

                </div>

                <!-- broker_sub -->
                <a class="font-weight-medium grey--text">Broker sub : </a>
                <div v-for="k in current_acl_wizard.content.value.broker_sub.length" v-bind:key="'C' + k">
                  <div class="bpub">
                    <p v-if="'pattern' in current_acl_wizard.content.value.broker_sub[k-1]">
                      <a class="font-weight-medium grey--text">Pattern : </a>
                      <v-text-field
                        v-model="current_acl_wizard.content.value.broker_sub[k-1].pattern"
                        :hide-details="true" style="margin-top: -6px;"
                        dense
                        single-line
                      ></v-text-field>
                    </p>

                    <p v-if="'max_qos' in current_acl_wizard.content.value.broker_sub[k-1]">
                      <a class="font-weight-medium grey--text">Max qos : </a>
                      <v-text-field
                        v-model="current_acl_wizard.content.value.broker_sub[k-1].max_qos"
                        :hide-details="true" style="margin-top: -6px;"
                        dense
                        single-line
                      ></v-text-field>
                    </p>
                    
                    <div v-if="'modifiers' in current_acl_wizard.content.value.broker_sub[k-1]">
                      <a class="font-weight-medium grey--text">Modifiers : </a>
                      <div class="bsub" v-for="l in current_acl_wizard.content.value.broker_sub[k-1].modifiers.length" v-bind:key="'D' + l">
                        <p>
                        <a style="margin-left: 20px;" class="font-weight-medium grey--text">Topic : </a>
                        <v-text-field
                          v-model="current_acl_wizard.content.value.broker_sub[k-1].modifiers[l-1][0]"
                          :hide-details="true" style="margin-top: -6px;"
                          dense
                          single-line
                        ></v-text-field>
                        </p>
                        <p>
                        <a style="margin-left: 20px;" class="font-weight-medium grey--text">Qos : </a>
                        <v-text-field
                          v-model="current_acl_wizard.content.value.broker_sub[k-1].modifiers[l-1][1]"
                          :hide-details="true" style="margin-top: -6px;"
                          dense
                          single-line
                        ></v-text-field>
                        </p>
                      </div>

                      <v-btn class="btnaddless" x-small icon @click="add_sub_modifier(k, 'edit')">
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>


                      <v-btn class="btnaddless" x-small icon @click="pop_sub_modifier(k, 'edit')">
                        <v-icon> mdi-minus </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>


                <v-btn class="btnaddless" x-small icon @click="add_broker('sub')">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>

                <v-btn class="btnaddless" x-small icon @click="incrementLength(false, 'bsub', 'edit')">
                  <v-icon> mdi-minus </v-icon>
                </v-btn>
                <br>

                <div v-if="show_add_sub">
                  <p>
                    <a class="font-weight-medium grey--text">max qos : </a>
                    <input class="checkbox" type="checkbox" id="max_qos" value="max_qos" v-model="add_broker_object.max_qos">
                    <label for="max_qos">max_qos</label>
                  </p>
                  <p>
                    <a class="font-weight-medium grey--text">modifiers : </a>
                    <input class="checkbox" type="checkbox" id="modifiers" value="modifiers" v-model="add_broker_object.modifiers">
                    <label for="modifiers">modifiers</label>
                  </p>
                  <v-btn class="btnaddless" x-small text @click="incrementLength(true, 'bsub', 'edit'),add_broker_false()"  >
                    OK
                  </v-btn>
                  <v-btn class="btnaddless" x-small text @click="add_broker_false()">
                    cancel
                  </v-btn>
                  <br>

                </div>


                  <a class="font-weight-medium grey--text">Comment : </a>
                <p>

                  <textarea class="input-comment"
                    v-model="current_acl_wizard.content.comment"
                    :counter="100"
                    label="Comment"></textarea>
                </p>
              </div>


              <div v-if="acl_unit.type=='remote'">
                  <p>
                    <a class="font-weight-medium grey--text">Name : </a>
                    <v-text-field
                      v-model="current_acl_wizard.content.name"
                      :hide-details="true" style="margin-top: -6px;"
                      dense
                      single-line
                    ></v-text-field>
                  </p>

                  <a class="font-weight-medium grey--text">SSH Server list : </a>
                  <a v-if="show_render">
                    <div v-if="current_acl_wizard.content.value.ssh">
                      <div v-for="i in current_acl_wizard.content.value.ssh.server_list.length" :key="i.ident" class="font-weight-regular black--text">
                        <v-text-field
                          v-model="current_acl_wizard.content.value.ssh.server_list[i-1]"
                          :hide-details="true"
                          dense
                          single-line
                        ></v-text-field>
                      </div>
                    </div>
                    <v-btn class="btnaddless" x-small icon @click="incrementLength(true, 'server_list_ssh', 'edit')">
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                    <v-btn class="btnaddless" x-small icon @click="incrementLength(false, 'server_list_ssh', 'edit')">
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                    <br>
                  </a>

                  <a class="font-weight-medium grey--text">OVPN Server list : </a>
                  <a v-if="show_render">
                    <div v-if="current_acl_wizard.content.value.ovpn">
                      <div v-for="j in current_acl_wizard.content.value.ovpn.server_list.length" :key="j.ident" class="font-weight-regular black--text">
                        <v-text-field
                          v-model="current_acl_wizard.content.value.ovpn.server_list[j-1]"
                          :hide-details="true"
                          dense
                          single-line
                        ></v-text-field>
                      </div>
                    </div>
                  </a>
                  <v-btn class="btnaddless" x-small icon @click="incrementLength(true, 'server_list_ovpn', 'edit')">
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                  <v-btn class="btnaddless" x-small icon @click="incrementLength(false, 'server_list_ovpn', 'edit')">
                    <v-icon> mdi-minus </v-icon>
                  </v-btn>
                  <br>


                  <a class="font-weight-medium grey--text">Comment : </a>
                  <p>
                    <textarea class="input-comment"
                      v-model="current_acl_wizard.content.comment"
                      :counter="100"
                      label="Comment"></textarea>
                  </p>

              </div>

            </div>


            <div v-if="json == true">
              <v-textarea
                solo
                name="ACL Content"
                label="ACL Content"
                v-model="current_acl.content"
                auto-grow
              >
              </v-textarea>
            </div>
          </div>
          <div v-else>

            <div v-if="json==false">
              <div v-if="acl_unit.type=='mqtt'">
                <p>
                  <a class="font-weight-medium grey--text">Name : </a>
                  <a class="font-weight-regular black--text">{{acl_unit.name}}</a>
                </p>
                <p>
                  <a class="font-weight-medium grey--text">Type : </a>
                  <a class="font-weight-regular black--text">{{acl_unit.type}}</a>
                </p>
                <p>
                  <a class="font-weight-medium grey--text">Ident : </a>
                  <a class="font-weight-regular black--text">{{acl_unit.ident}}</a>
                </p>
                <p>
                  <a class="font-weight-medium grey--text">Entity_ident : </a>
                  <a class="font-weight-regular black--text">{{acl_unit.entity_ident}}</a>
                </p>
                <p v-if="acl_unit.value.server_list.length != 0">
                  <a class="font-weight-medium grey--text">Server list : </a>
                  <a v-for="item1 in acl_unit.value.server_list" :key="item1.ident" class="font-weight-regular black--text"><br>{{item1}}</a>
                </p>
                <p v-if="acl_unit.value.broker_pub.length != 0">
                  <a class="font-weight-medium grey--text">Broker pub : </a>
                  <a v-for="item2 in acl_unit.value.broker_pub" :key="item2.ident" class="font-weight-regular black--text"><br>{{item2}}</a>
                </p>
                <p v-if="acl_unit.value.broker_sub.length != 0">
                  <a class="font-weight-medium grey--text">Broker sub : </a>
                  <a v-for="item3 in acl_unit.value.broker_sub" :key="item3.ident" class="font-weight-regular black--text"><br>{{item3}}</a>
                </p>
                <p v-if="acl_unit.comment">
                  <a class="font-weight-medium grey--text">Comment : </a>
                  <a class="font-weight-regular black--text"><br>{{acl_unit.comment}}</a>
                </p>
              </div>

              <div v-if="acl_unit.type=='remote'">
                <p>
                  <a class="font-weight-medium grey--text">Name : </a>
                  <a class="font-weight-regular black--text">{{acl_unit.name}}</a>
                </p>
                <p>
                  <a class="font-weight-medium grey--text">Type : </a>
                  <a class="font-weight-regular black--text">{{acl_unit.type}}</a>
                </p>
                <p>
                  <a class="font-weight-medium grey--text">Ident : </a>
                  <a class="font-weight-regular black--text">{{acl_unit.ident}}</a>
                </p>
                <p>
                  <a class="font-weight-medium grey--text">Entity_ident : </a>
                  <a class="font-weight-regular black--text">{{acl_unit.entity_ident}}</a>
                </p>
                <p v-if="acl_unit.value.ssh">
                  <a class="font-weight-medium grey--text">SSH Server list : </a>
                  <a v-for="item4 in acl_unit.value.ssh.server_list" :key="item4.ident" class="font-weight-regular black--text"><br>{{item4}}</a>
                </p>
                <p v-if="acl_unit.value.ovpn">
                  <a class="font-weight-medium grey--text">OVPN Server list : </a>
                  <a v-for="item5 in acl_unit.value.ovpn.server_list" :key="item5.ident" class="font-weight-regular black--text"><br>{{item5}}</a>
                </p>
                <p v-if="acl_unit.comment">
                  <a class="font-weight-medium grey--text">Comment : </a>
                  <a class="font-weight-regular black--text"><br>{{acl_unit.comment}}</a>
                </p>
              </div>
            </div>
            <div v-if="json==true">
              <vue-json-pretty :path="'res'" :data="acl_unit" mb-4>
              </vue-json-pretty>
            </div>
            

          </div>

          <a >
            <v-btn 
              outlined
              rounded
              text
              v-if="!(editing_index == index)"
              @click="editAclWizard(index)"
            >
              Edit
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
          </a>

          <a v-if="json==false">
            <v-btn
              outlined
              rounded
              text
              v-if="editing_index == index"
              v-on:click="saveAclWizard(current_acl_wizard, 'edit')"
            >
              Save
              <v-icon> mdi-content-save </v-icon>
            </v-btn>
          </a>

          <a v-if="json==true">
            <v-btn
              outlined
              rounded
              text
              v-if="editing_index == index"
              v-on:click="saveAcl(current_acl, 'edit')"
            >
              Save
              <v-icon> mdi-content-save </v-icon>
            </v-btn>
          </a>
          <v-btn
            outlined
            rounded
            text
            v-if="editing_index == index"
            v-on:click="editing_index = null"
          >
            Cancel
            <v-icon> mdi-close </v-icon>
          </v-btn>


          <v-btn
            outlined
            rounded
            text
            v-if="editing_index == index"
            @click="deleteAcl(index)"
          >
            Delete
            <v-icon> mdi-delete-outline </v-icon>
          </v-btn>

        </v-card>


        <v-dialog v-model="new_dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              rounded
              text
              v-bind="attrs"
              v-on="on"
              @click="addAcl"
            >
              Add ACL
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>



          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              New ACL
            </v-card-title>



            <v-card-text v-if="create_acl_json">
              <v-col>
                <div class="caption grey--text">Type</div>
                <v-select
                  v-model="new_acl.meta.type"
                  :items="this.$store.getters.defaultVal.ACLTypes"
                  :hide-details="true" style="margin-top: -6px;"
                  dense
                  single-line
                ></v-select>
              </v-col>
              <v-col>
                <v-textarea
                  solo
                  name="new_acl"
                  label="default ACL"
                  v-model="new_acl.content"
                  auto-grow
                >
                </v-textarea>
              </v-col>
            </v-card-text>

            <v-card-text v-if="create_acl_json==false">

              <div v-if="show_type_btn" class="type_btn">
                <a >
                  <v-btn outlined rounded text @click="type('mqtt')">
                    mqtt
                  </v-btn>
                </a>
                <a class="type_btn_spacer"></a>
                <a>
                  <v-btn outlined rounded text @click="type('remote')">
                    remote
                  </v-btn>
                </a>
              </div>
              <div v-if="show_type_btn==false" class="type_btn">
                <a  class="type_btn_back">
                  <v-btn outlined rounded text @click="type('back')"> 
                    <v-icon> mdi-arrow-left </v-icon>
                  </v-btn>
                </a>
                <a class="type_btn_spacer"></a>
                <a class="type_btn_right">
                  <v-btn outlined rounded text @click="create_change_json()">
                    <v-icon> mdi-code-json </v-icon>
                  </v-btn>
                </a>
              </div>


              <div v-if="show_create_acl">

                <!-- JSON -->
                <div v-if="json_create_acl">
                  <v-textarea
                    solo
                    name="ACL Content"
                    label="ACL Content"
                    v-model="current_acl_create_json"
                    auto-grow
                  >
                  </v-textarea>
                </div>
        
                <!-- WIZARD -->
                <div v-if="json_create_acl == false">
                  <div v-if="type_create_acl == 'mqtt'">
                    
                    <a class="font-weight-medium grey--text">Name : </a>
                    <v-text-field
                      v-model="current_acl_create_wizard.name"
                      :hide-details="true" style="margin-top: -6px;"
                      dense
                      single-line
                    ></v-text-field>
                    
                    <!-- server_list -->
                    <a class="font-weight-medium grey--text">Server list : </a>
                    <div v-for="i in current_acl_create_wizard.value.server_list.length" v-bind:key="'A' + i">
                      <v-text-field
                        v-model="current_acl_create_wizard.value.server_list[i-1]"
                        :hide-details="true"
                        dense
                        single-line
                      ></v-text-field>
                    </div>

                    <v-btn class="btnaddless" x-small icon @click="incrementLength(true, 'server_list', 'create')">
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                    <v-btn class="btnaddless" x-small icon @click="incrementLength(false, 'server_list', 'create')">
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                    <br>

                    <!-- broker_pub -->
                    <a class="font-weight-medium grey--text">Broker pub : </a>
                    <div v-for="j in current_acl_create_wizard.value.broker_pub.length" v-bind:key="'B' + j">
                      <div class="bpub">
                        <p class="" v-if="'pattern' in current_acl_create_wizard.value.broker_pub[j-1]">
                          <a  class="font-weight-medium grey--text">pattern : </a>
                          <v-text-field
                            v-model="current_acl_create_wizard.value.broker_pub[j-1].pattern"
                            :hide-details="true" style="margin-top: -6px;"
                            dense
                            single-line
                          ></v-text-field>
                        </p>
                        <p v-if="'max_qos' in current_acl_create_wizard.value.broker_pub[j-1]">
                          <a class="font-weight-medium grey--text">max qso : </a>
                          <v-text-field
                            v-model="current_acl_create_wizard.value.broker_pub[j-1].max_qos"
                            :hide-details="true" style="margin-top: -6px;"
                            dense
                            single-line
                          ></v-text-field>
                        </p>
                        <p v-if="'max_payload_size' in current_acl_create_wizard.value.broker_pub[j-1]">
                          <a class="font-weight-medium grey--text">max payload size : </a>
                          <v-text-field
                            v-model="current_acl_create_wizard.value.broker_pub[j-1].max_payload_size"
                            :hide-details="true" style="margin-top: -6px;"
                            dense
                            single-line
                          ></v-text-field>
                        </p>
                        <p v-if="'allowed_retain' in current_acl_create_wizard.value.broker_pub[j-1]">
                          <a class="font-weight-medium grey--text">allowed retain : </a>
                          <v-text-field
                            v-model="current_acl_create_wizard.value.broker_pub[j-1].allowed_retain"
                            :hide-details="true" style="margin-top: -6px;"
                            dense
                            single-line
                          ></v-text-field>
                        </p>
                        <div v-if="'modifiers' in current_acl_create_wizard.value.broker_pub[j-1]">
                          <a class="font-weight-medium grey--text">modifiers : </a>
                          <p v-if="'topic' in current_acl_create_wizard.value.broker_pub[j-1].modifiers">
                            <a style="margin-left: 20px;" class="font-weight-medium grey--text">topic : </a>
                            <v-text-field
                              v-model="current_acl_create_wizard.value.broker_pub[j-1].modifiers.topic"
                              :hide-details="true" style="margin-top: -6px;"
                              dense
                              single-line
                            ></v-text-field>
                          </p>
                          <p v-if="'payload' in current_acl_create_wizard.value.broker_pub[j-1].modifiers">
                            <a style="margin-left: 20px;" class="font-weight-medium grey--text">payload : </a>
                            <v-text-field
                              v-model="current_acl_create_wizard.value.broker_pub[j-1].modifiers.payload"
                              :hide-details="true" style="margin-top: -6px;"
                              dense
                              single-line
                            ></v-text-field>
                          </p>
                          <p v-if="'qos' in current_acl_create_wizard.value.broker_pub[j-1].modifiers">
                            <a style="margin-left: 20px;" class="font-weight-medium grey--text">qos : </a>
                            <v-text-field
                              v-model="current_acl_create_wizard.value.broker_pub[j-1].modifiers.qos"
                              :hide-details="true" style="margin-top: -6px;"
                              dense
                              single-line
                            ></v-text-field>
                          </p>
                          <p v-if="'retain' in current_acl_create_wizard.value.broker_pub[j-1].modifiers">
                            <a style="margin-left: 20px;" class="font-weight-medium grey--text">retain : </a>
                            <v-text-field
                              v-model="current_acl_create_wizard.value.broker_pub[j-1].modifiers.retain"
                              :hide-details="true" style="margin-top: -6px;"
                              dense
                              single-line
                            ></v-text-field>
                          </p>
                          <p v-if="'mountpoint' in current_acl_create_wizard.value.broker_pub[j-1].modifiers">
                            <a style="margin-left: 20px;" class="font-weight-medium grey--text">mountpoint : </a>
                            <v-text-field
                              v-model="current_acl_create_wizard.value.broker_pub[j-1].modifiers.mountpoint"
                              :hide-details="true" style="margin-top: -6px;"
                              dense
                              single-line
                            ></v-text-field>
                          </p>
                        </div>
                      </div>
                    </div>


                    <v-btn class="btnaddless" x-small icon @click="add_broker('pub')">
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>

                    <v-btn class="btnaddless" x-small icon @click="incrementLength(false, 'bpub', 'create')">
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                    <br>

                    <div v-if="show_add_pub">

                      <input class="input-checkbox" type="checkbox" id="max_qos" value="max_qos" v-model="add_broker_object.max_qos">
                      <label for="max_qos">max_qos</label>
                      <br>
                      <input class="input-checkbox" type="checkbox" id="max_payload_size" value="max_payload_size" v-model="add_broker_object.max_payload_size">
                      <label for="max_payload_size">max_payload_size</label>
                      <br>
                      <input class="input-checkbox" type="checkbox" id="allowed_retain" value="allowed_retain" v-model="add_broker_object.allowed_retain">
                      <label for="allowed_retain">allowed_retain</label>
                      <br>
                      <input class="input-checkbox" type="checkbox" id="modifiers" value="modifiers" v-model="add_broker_object.modifiers" @click="show_pub_modifier()">
                      <label for="modifiers">modifiers</label>
                      <br>
                      <div class="modifiers" v-if="show_pub_modifiers">
                        <input class="input-checkbox" type="checkbox" id="modifiers_topic" value="modifiers_topic" v-model="add_broker_object.modifiers_topic">
                        <label for="modifiers_topic">modifiers_topic</label>
                        <br>
                        <input class="input-checkbox" type="checkbox" id="modifiers_payload" value="modifiers_payload" v-model="add_broker_object.modifiers_payload">
                        <label for="modifiers_payload">modifiers_payload</label>
                        <br>
                        <input class="input-checkbox" type="checkbox" id="modifiers_qos" value="modifiers_qos" v-model="add_broker_object.modifiers_qos">
                        <label for="modifiers_qos">modifiers_qos</label>
                        <br>
                        <input class="input-checkbox" type="checkbox" id="modifiers_retain" value="modifiers_retain" v-model="add_broker_object.modifiers_retain">
                        <label for="modifiers_retain">modifiers_retain</label>
                        <br>
                        <input class="input-checkbox" type="checkbox" id="modifiers_mountpoint" value="modifiers_mountpoint" v-model="add_broker_object.modifiers_mountpoint">
                        <label for="modifiers_mountpoint">modifiers_mountpoint</label>
                        <br>
                      </div>

                      <v-btn class="btnaddless" x-small text @click="incrementLength(true, 'bpub', 'create'), add_broker_false(), hide_pub_modifier()"  >
                        OK
                      </v-btn>
                      <v-btn class="btnaddless" x-small text @click="add_broker_false(), hide_pub_modifier()">
                        cancel
                      </v-btn>
                      <br>

                    </div>

                    <!-- broker_sub -->
                    <a class="font-weight-medium grey--text">Broker sub : </a>
                    <div v-for="k in current_acl_create_wizard.value.broker_sub.length" v-bind:key="'C' + k">
                      <div class="bpub">
                        <p v-if="'pattern' in current_acl_create_wizard.value.broker_sub[k-1]">
                          <a class="font-weight-medium grey--text">Pattern : </a>
                          <v-text-field
                            v-model="current_acl_create_wizard.value.broker_sub[k-1].pattern"
                            :hide-details="true" style="margin-top: -6px;"
                            dense
                            single-line
                          ></v-text-field>
                        </p>

                        <p v-if="'max_qos' in current_acl_create_wizard.value.broker_sub[k-1]">
                          <a class="font-weight-medium grey--text">Max qos : </a>
                          <v-text-field
                            v-model="current_acl_create_wizard.value.broker_sub[k-1].max_qos"
                            :hide-details="true" style="margin-top: -6px;"
                            dense
                            single-line
                          ></v-text-field>
                        </p>
                        
                        <div v-if="'modifiers' in current_acl_create_wizard.value.broker_sub[k-1]">
                          <a class="font-weight-medium grey--text">Modifiers : </a>
                          <div class="bsub" v-for="l in current_acl_create_wizard.value.broker_sub[k-1].modifiers.length" v-bind:key="'D' + l">
                            <p>
                            <a style="margin-left: 20px;" class="font-weight-medium grey--text">Topic : </a>
                            <v-text-field
                              v-model="current_acl_create_wizard.value.broker_sub[k-1].modifiers[l-1][0]"
                              :hide-details="true" style="margin-top: -6px;"
                              dense
                              single-line
                            ></v-text-field>
                            </p>
                            <p>
                            <a style="margin-left: 20px;" class="font-weight-medium grey--text">Qos : </a>
                            <v-text-field
                              v-model="current_acl_create_wizard.value.broker_sub[k-1].modifiers[l-1][1]"
                              :hide-details="true" style="margin-top: -6px;"
                              dense
                              single-line
                            ></v-text-field>
                            </p>
                          </div>

                          <v-btn class="btnaddless" x-small icon @click="add_sub_modifier(k, 'create')">
                            <v-icon> mdi-plus </v-icon>
                          </v-btn>


                          <v-btn class="btnaddless" x-small icon @click="pop_sub_modifier(k, 'create')">
                            <v-icon> mdi-minus </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>


                    <v-btn class="btnaddless" x-small icon @click="add_broker('sub')">
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>

                    <v-btn class="btnaddless" x-small icon @click="incrementLength(false, 'bsub', 'create')">
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                    <br>

                    <div v-if="show_add_sub">
                      <p>
                        <a class="font-weight-medium grey--text">max qos : </a>
                        <input class="checkbox" type="checkbox" id="max_qos" value="max_qos" v-model="add_broker_object.max_qos">
                        <label for="max_qos">max_qos</label>
                      </p>
                      <p>
                        <a class="font-weight-medium grey--text">modifiers : </a>
                        <input class="checkbox" type="checkbox" id="modifiers" value="modifiers" v-model="add_broker_object.modifiers">
                        <label for="modifiers">modifiers</label>
                      </p>
                      <v-btn class="btnaddless" x-small text @click="incrementLength(true, 'bsub', 'create'),add_broker_false()"  >
                        OK
                      </v-btn>
                      <v-btn class="btnaddless" x-small text @click="add_broker_false()">
                        cancel
                      </v-btn>
                      <br>
                    </div>
                      <a class="font-weight-medium grey--text">Comment : </a>
                      <p>
                        <textarea class="input-comment"
                          v-model="current_acl_create_wizard.comment"
                          :counter="100"
                          label="Comment"></textarea>
                      </p>
                  </div>

                  <div v-if="type_create_acl == 'remote'">

                    <p>
                      <a class="font-weight-medium grey--text">Name : </a>
                      <v-text-field
                        v-model="current_acl_create_wizard.name"
                        :hide-details="true" style="margin-top: -6px;"
                        dense
                        single-line
                      ></v-text-field>
                    </p>

                    <a class="font-weight-medium grey--text">SSH Server list : </a>
                    <a v-if="show_render">
                      <div v-if="current_acl_create_wizard.value.ssh">
                        <div v-for="i in current_acl_create_wizard.value.ssh.server_list.length" :key="i.ident" class="font-weight-regular black--text">
                          <v-text-field
                            v-model="current_acl_create_wizard.value.ssh.server_list[i-1]"
                            :hide-details="true"
                            dense
                            single-line
                          ></v-text-field>
                        </div>
                      </div>
                      <v-btn class="btnaddless" x-small icon @click="incrementLength(true, 'server_list_ssh', 'create')">
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>
                      <v-btn class="btnaddless" x-small icon @click="incrementLength(false, 'server_list_ssh', 'create')">
                        <v-icon> mdi-minus </v-icon>
                      </v-btn>
                      <br>
                    </a>

                    <a class="font-weight-medium grey--text">OVPN Server list : </a>
                    <a v-if="show_render">
                      <div v-if="current_acl_create_wizard.value.ovpn">
                        <div v-for="j in current_acl_create_wizard.value.ovpn.server_list.length" :key="j.ident" class="font-weight-regular black--text">
                          <v-text-field
                            v-model="current_acl_create_wizard.value.ovpn.server_list[j-1]"
                            :hide-details="true"
                            dense
                            single-line
                          ></v-text-field>
                        </div>
                      </div>
                    </a>
                    <v-btn class="btnaddless" x-small icon @click="incrementLength(true, 'server_list_ovpn', 'create')">
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                    <v-btn class="btnaddless" x-small icon @click="incrementLength(false, 'server_list_ovpn', 'create')">
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                    <br>

                    <a class="font-weight-medium grey--text">Comment : </a>
                    <p>
                      <textarea class="input-comment"
                        v-model="current_acl_create_wizard.comment"
                        :counter="100"
                        label="Comment"></textarea>
                    </p>
                  </div>
                </div>
              </div>


            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="json_create_acl" color="primary" text @click="saveAcl(current_acl_create_json, 'create')">
                Create ACL
              </v-btn>
              <v-btn v-if="json_create_acl == false" color="primary" text @click="saveAclWizard(current_acl_create_wizard, 'create')">
                Create ACL
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-col>
    </v-row>
  </div>
</template>


<script>
// import AclsEdit from "@/components/entities/AclsEdit";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";


export default {
  props: ["entity"],
  components: { VueJsonPretty },

  data: function () {
    return {
      current_acl_create_json: {},
      current_acl_create_wizard: {},
      json_create_acl: false,
      type_create_acl: "",
      show_render: true,
      show_type_btn: true,
      show_pub_modifiers: false,
      code: "",
      lineNumbers: true,
      show_add_pub: false,
      show_add_sub: false,
      add_broker_object: {
        "max_qos": false,
        "max_payload_size": false,
        "allowed_retain": false,
        "modifiers": false,
        "modifiers_topic": false,
        "modifiers_payload": false,
        "modifiers_qos": false,
        "modifiers_retain": false,
        "modifiers_mountpoint": false,
      },
      createAclDialog: false,
      typeEdit: "wizard",
      json: false,
      create_acl_json: false,
      defaultAcl: {
        name: "",
        value: "",
        edit: false,
      },
      aclNameRules: [
        (v) => !!v || "Name is required",
        (v) => (v || "").indexOf(" ") < 0 || "No spaces are allowed",
      ],
      newAcl: {
        name: "",
        value: "",
        edit: false,
      },
      itemsPerPageArray: [10, 25, 50],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 10,
      sortBy: "name",
      keys: ["Name", "Value", "Action"],
      items: [],
      acl: [],
      current_acl: { meta: { ident: null } },
      current_acl_wizard: { meta: { ident: null } },
      editing_index: null,
      default_acl: {
        content: {
          name: "",
          value: "",
          comment: "",
        },
        meta: {
          type: null,
          entity_ident: "",
        },
      },
      new_acl: {
        content: {
          name: "",
          value: "",
          comment: "",
        },
        meta: {
          type: null,
          entity_ident: "",
        },
      },
      new_dialog: false
    };
  },
  created() {
    this.getInfos();
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  methods: {
    create_change_json(){
      if(this.json_create_acl) {
        this.json_create_acl = false
        this.current_acl_create_wizard = JSON.parse(this.current_acl_create_json);
      }else{
        this.json_create_acl = true
        this.current_acl_create_json = JSON.stringify(this.current_acl_create_wizard, null, 4);
      }
    },
    type(acl_type){
      let acl = {
        "name": "",
        "value": {},
        "comment": ""
      }
      
      if(acl_type == "mqtt"){
        this.type_create_acl = 'mqtt'
        acl.value = {
          "broker_pub": [],
          "broker_sub": [],
          "server_list": []
        }
        console.log(acl)
      }
      if(acl_type == "remote"){
        this.type_create_acl = 'remote'
      }
      this.show_type_btn = false
      this.show_create_acl = true
      
      if(acl_type == "back"){
        //reset values
        this.show_type_btn = true
        this.type_create_acl = ''
        this.show_create_acl = false
      }
      this.current_acl_create_wizard = acl
      this.current_acl_create_json = JSON.stringify(acl, null, 4);

    },
    pop_sub_modifier(index, action) {
      if(action == 'edit'){
        this.current_acl_wizard.content.value.broker_sub[index-1].modifiers.pop()
      }
      if(action == 'create'){
        this.ccurrent_acl_create_wizard.value.broker_sub[index-1].modifiers.pop()
      }
    },

    add_sub_modifier(index, action) {
      if(action == 'edit'){
        this.current_acl_wizard.content.value.broker_sub[index-1].modifiers.push(["", 0])
      }
      if(action == 'create'){
        this.current_acl_create_wizard.value.broker_sub[index-1].modifiers.push(["", 0])
      }
    },
    show_pub_modifier() {
      if(this.show_pub_modifiers){
        this.hide_pub_modifier()
      }else{
        this.show_pub_modifiers = true
      }
    },
    hide_pub_modifier() {
      this.show_pub_modifiers = false
      this.add_broker_object.modifiers_topic = false,
      this.add_broker_object.modifiers_payload = false,
      this.add_broker_object.modifiers_qos = false,
      this.add_broker_object.modifiers_retain = false,
      this.add_broker_object.modifiers_mountpoint = false
    },
    add_broker(type){
      if(type == 'pub'){
        this.show_add_pub = true
      }
      if(type == 'sub'){
        this.show_add_sub = true
      }
    },
    add_broker_false(){
      this.show_add_pub = false
      this.show_add_sub = false
      this.add_broker_object.max_qos = false
      this.add_broker_object.max_payload_size = false
      this.add_broker_object.allowed_retain = false
      this.add_broker_object.modifiers = false
    },
    incrementLength(bool, type, action){
      if(type=="server_list"){
        if(bool){
          if(action == "edit"){
            this.current_acl_wizard.content.value.server_list.push("")
          }
          if(action == "create"){
            this.current_acl_create_wizard.value.server_list.push("")
          }
        }else{
          if(action == "edit"){
            this.current_acl_wizard.content.value.server_list.pop()
          }
          if(action == "create"){
            this.current_acl_create_wizard.value.server_list.pop()
          }
        }
      }
      if(type=="server_list_ssh"){
        if(bool){
          if(action == "edit"){
            if("ssh" in this.current_acl_wizard.content.value == false){
              this.current_acl_wizard.content.value["ssh"] = {
                "server_list": []
              }
              console.log(this.current_acl_wizard.content.value)
            }
            this.current_acl_wizard.content.value.ssh.server_list.push("")
            this.show_render = false
            this.show_render = true
          }
          if(action == "create"){
            if("ssh" in this.current_acl_create_wizard.value == false){
              this.current_acl_create_wizard.value["ssh"] = {
                "server_list": []
              }
              console.log(this.current_acl_create_wizard.value)
            }
            this.current_acl_create_wizard.value.ssh.server_list.push("")
            this.show_render = false
            this.show_render = true
          }
        }else{
          if(action == "edit"){
            this.current_acl_wizard.content.value.ssh.server_list.pop()
            this.show_render = false
            this.show_render = true
            if(this.current_acl_wizard.content.value.ssh.server_list.length == 0){
              delete this.current_acl_wizard.content.value['ssh']
            }
          }
          if(action == "create"){
            this.current_acl_create_wizard.value.ssh.server_list.pop()
            this.show_render = false
            this.show_render = true
            if(this.current_acl_create_wizard.value.ssh.server_list.length == 0){
              delete this.current_acl_create_wizard.value['ssh']
            }
          }
        }
      }
      if(type=="server_list_ovpn"){
        if(bool){
          if(action == "edit"){
            if("ovpn" in this.current_acl_wizard.content.value == false){
              this.current_acl_wizard.content.value["ovpn"] = {
                "server_list": []
              }
              console.log(this.current_acl_wizard.content.value)
            }
            this.current_acl_wizard.content.value.ovpn.server_list.push("")
            this.show_render = false
            this.show_render = true
          }
          if(action == "create"){
            if("ovpn" in this.current_acl_create_wizard.value == false){
              this.current_acl_create_wizard.value["ovpn"] = {
                "server_list": []
              }
              console.log(this.current_acl_create_wizard.value)
            }
            this.current_acl_create_wizard.value.ovpn.server_list.push("")
            this.show_render = false
            this.show_render = true
          }
        }else{
          if(action == "edit"){
            this.current_acl_wizard.content.value.ovpn.server_list.pop()
            this.show_render = false
            this.show_render = true
            if(this.current_acl_wizard.content.value.ovpn.server_list.length == 0){
              delete this.current_acl_wizard.content.value['ovpn']
            }
          }
          if(action == "create"){
            this.current_acl_create_wizard.value.ovpn.server_list.pop()
            this.show_render = false
            this.show_render = true
            if(this.current_acl_create_wizard.value.ovpn.server_list.length == 0){
              delete this.current_acl_create_wizard.value['ovpn']
            }
          }
        }
      }
      if(type=="bpub"){
        if(bool){

          if(action == "edit"){
            let json = {}
            json['pattern'] = ""
            if(this.add_broker_object.max_qos){
              json['max_qos'] = ""
            }
            if(this.add_broker_object.max_payload_size){
              json['max_payload_size'] = ""
            }
            if(this.add_broker_object.allowed_retain){
              json['allowed_retain'] = ""
            }
            if(this.add_broker_object.modifiers){
              json['modifiers'] = {}
              if(this.add_broker_object.modifiers_topic){
                json['modifiers']['topic'] = ""
              }
              if(this.add_broker_object.modifiers_payload){
                json['modifiers']['payload'] = ""
              }
              if(this.add_broker_object.modifiers_qos){
                json['modifiers']['qos'] = ""
              }
              if(this.add_broker_object.modifiers_retain){
                json['modifiers']['retain'] = ""
              }
              if(this.add_broker_object.modifiers_mountpoint){
                json['modifiers']['mountpoint'] = ""
              }
            }
            this.current_acl_wizard.content.value.broker_pub.push(json)
          }
          if(action == "create"){
            let json = {}
            json['pattern'] = ""
            if(this.add_broker_object.max_qos){
              json['max_qos'] = ""
            }
            if(this.add_broker_object.max_payload_size){
              json['max_payload_size'] = ""
            }
            if(this.add_broker_object.allowed_retain){
              json['allowed_retain'] = ""
            }
            if(this.add_broker_object.modifiers){
              json['modifiers'] = {}
              if(this.add_broker_object.modifiers_topic){
                json['modifiers']['topic'] = ""
              }
              if(this.add_broker_object.modifiers_payload){
                json['modifiers']['payload'] = ""
              }
              if(this.add_broker_object.modifiers_qos){
                json['modifiers']['qos'] = ""
              }
              if(this.add_broker_object.modifiers_retain){
                json['modifiers']['retain'] = ""
              }
              if(this.add_broker_object.modifiers_mountpoint){
                json['modifiers']['mountpoint'] = ""
              }
            }
            this.current_acl_create_wizard.value.broker_pub.push(json)
          }
        }else{
          if(action == "edit"){
            this.current_acl_wizard.content.value.broker_pub.pop()
          }
          if(action == "create"){
            this.current_acl_create_wizard.value.broker_pub.pop()
          }
        }
      }
      if(type=="bsub"){
        if(bool){
          if(action == "edit"){
            let json = {}
            json['pattern'] = ""
            if(this.add_broker_object.max_qos){
              json['max_qos'] = ""
            }
            if(this.add_broker_object.max_payload_size){
              json['max_payload_size'] = ""
            }
            if(this.add_broker_object.allowed_retain){
              json['allowed_retain'] = ""
            }
            if(this.add_broker_object.modifiers){
              json['modifiers'] = []
            }
            this.current_acl_wizard.content.value.broker_sub.push(json)
          }
          if(action == "create"){
            let json = {}
            json['pattern'] = ""
            if(this.add_broker_object.max_qos){
              json['max_qos'] = ""
            }
            if(this.add_broker_object.max_payload_size){
              json['max_payload_size'] = ""
            }
            if(this.add_broker_object.allowed_retain){
              json['allowed_retain'] = ""
            }
            if(this.add_broker_object.modifiers){
              json['modifiers'] = []
            }
            this.current_acl_create_wizard.value.broker_sub.push(json)
          }
        }else{
          if(action == "edit"){
            this.current_acl_wizard.content.value.broker_sub.pop()
          }
          if(action == "create"){
            this.current_acl_create_wizard.value.broker_sub.pop()
          }
        }
      }


    },
    changeJson(){
      if(this.json) {
        this.json = false
        this.current_acl_wizard.content = JSON.parse(this.current_acl.content);
      }else{
        this.json = true
        this.current_acl.content = JSON.stringify(this.current_acl_wizard.content, null, 4);
      }
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    getInfos() {
      this.$store.commit("showLoader");
      Promise.all([this.getAcl()])
        .then((values) => {
          //first return value
          this.acl = values[0];
          // this.num_acl = this.acl.length()
          //second return value
          this.$store.commit("hideLoader");
          console.log('updated')
        })
        .catch((error) => {
          this.errormsg = error.response.data.message;
        });
        
    },
    async getAcl() {
      this.$store.commit("showLoader");
      const response = await this.$http({
        method: "get",
        url: "/api/entities/" + this.entity.ident + "/acl",
      })
        .then((result) => {
          //On traite la suite une fois la réponse obtenue
          let data = result.data;
          console.log(data);
          return data;
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
      //   }
      //refresh entity info
      response.sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0));
      this.$store.commit("hideLoader");
      return response;
    },
    //edit selected acls
    editAcl(index) {
      this.editing_index = index;
      let acl = {
        index: index,
        content: {
          name: this.acl[index].name,
          value: this.acl[index].value,
          comment: this.acl[index].comment,
        },
        meta: {
          type: this.acl[index].type,
          ident: this.acl[index].ident,
          status: this.acl[index].status,
          entity_ident: this.acl[index].entity_ident,
        },
      };

      acl.content = JSON.stringify(acl.content, null, 4);
      this.current_acl = acl;

    },
    editAclWizard(index) {
      this.editing_index = index;
      let acl = {
        index: index,
        content: {
          name: this.acl[index].name,
          value: this.acl[index].value,
          comment: this.acl[index].comment,
        },
        meta: {
          type: this.acl[index].type,
          ident: this.acl[index].ident,
          status: this.acl[index].status,
          entity_ident: this.acl[index].entity_ident,
        },
      };

      this.current_acl_wizard = acl;

      if(this.json == false) {
        this.json = true
        this.editAcl(index)
        this.json = false
      }
      if(this.json == true) {
        this.editAcl(index)
        this.json = true
      }


    },

    //add acl to list
    addAcl() {
      this.new_dialog = true
      this.new_acl = {...this.default_acl}
      this.new_acl.meta.entity_ident = this.entity.ident
      this.new_acl.content = JSON.stringify(this.new_acl.content, null, 4);
      console.log(this.new_acl)
    },

    //save new acls
    saveAcl(acl, action) {

      this.new_dialog = false
      this.$store.commit("showLoader");
      
      // prepare commiting to API
      let data = {};
      let new_url = "";

      if(action == 'edit'){
        acl.content = JSON.parse(acl.content);
        new_url = "/api/entities/" + acl.meta.entity_ident + "/acl/" + acl.meta.type
        data = acl.content;
      }
      if(action == 'create'){
        acl = JSON.parse(acl);
        console.log(acl)
        new_url = "/api/entities/" + this.entity.ident + "/acl/" + this.type_create_acl
        data = acl;
      }


      this.$http({
        method: "post",
        url: new_url,
        data: data,
      })
        .then((result) => {
          //On traite la suite une fois la réponse obtenue

          data = result.data;
          console.log(data);
          this.$store.commit("hideLoader");
          this.editing_index = null;
          this.getInfos()
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
    },
    saveAclWizard(acl, action) {
      this.new_dialog = false
      this.$store.commit("showLoader");

      console.log(acl);

      // prepare commiting to API
      let data = {}


      let new_url = ""

      if(action == 'edit'){
        new_url = "/api/entities/" + acl.meta.entity_ident + "/acl/" + acl.meta.type
        data = acl.content;
      }
      if(action == 'create'){
        new_url = "/api/entities/" + this.entity.ident + "/acl/" + this.type_create_acl
        data = acl;
      }

      this.$http({
        method: "post",
        url: new_url,
        data: data,
      })
        .then((result) => {
          //On traite la suite une fois la réponse obtenue
          data = result.data;
          console.log(data);
          this.$store.commit("hideLoader");
          this.editing_index = null;
          this.getInfos()
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
    },

    //edit shown entity
    deleteAcl(index) {
      this.$store.commit("showLoader");
      // prepare commiting to API

      this.$http({
        method: "delete",
        url: "/api/entities/" + this.acl[index].entity_ident + "/acl/" + this.acl[index].type,
      })
        .then(() => {
          //On traite la suite une fois la réponse obtenue
          this.$store.commit("hideLoader");
          this.getInfos()
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
    },
  },
};
</script>
<style>

.btnJson {
 margin-top: 10px;
}

.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

.prism-editor__textarea:focus {
  outline: none;
}

.modifiers {
  margin-left: 20px;
}

.input {
  width: 100%;
  border-bottom: 1px solid rgb(180, 180, 180);
  border: 0,5px solid rgb(180, 180, 180);
}

.input-checkbox {
  width: 5%;
  border-bottom: 1px solid rgb(180, 180, 180);
  border: 0,5px solid rgb(180, 180, 180);
}

.input-comment {
  width: 100%;
  border: 1px solid rgb(180, 180, 180);
  border-bottom: 1px solid rgb(180, 180, 180);
}

.bpub {
  border-left: 1px solid rgb(180, 180, 180);
  border: 0,5px solid rgb(180, 180, 180);
  border-radius: 4px;
  padding-left: 10px;
}

.bsub {
  border-left: 1px solid rgb(180, 180, 180);
  border: 0,5px solid rgb(180, 180, 180);
  border-radius: 4px;
  margin-left: 10px;
}

.btnaddless {
  padding: 2px;
}

.type_btn {
  text-align: center;
  margin-top: 10px
}

.type_btn_back {
  float: left;
  margin-left: 10px;
}

.type_btn_spacer {
  margin-left: 20px;
}

.type_btn_right {
  margin-right: 20%;
}

</style>






