<template>
  <div id="entity-attributes-edit">
    <v-row>
      <v-col cols="12">
        <v-data-iterator
          :items="items"
          :items-per-page.sync="itemsPerPage"
          :page.sync="page"
          :search="search"
          :sort-by="sortBy.toLowerCase()"
          :sort-desc="sortDesc"
          hide-default-footer
        >
          <template v-slot:header>
            <v-toolbar class="mb-1" dense>
              <v-text-field
                v-model="search"
                clearable
                flat
                solo
                hide-details
                dense
                prepend-inner-icon="mdi-magnify"
                label="Search Attribute"
              ></v-text-field>

              <v-spacer></v-spacer>
              <v-btn icon @click="sortDesc = !sortDesc">
                <v-icon v-if="sortDesc">mdi-arrow-down</v-icon>
                <v-icon v-else>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn icon @click="addAttribute()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar>
          </template>

          
          <template v-slot:default="props">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Values</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in props.items" :key="item.name">
                  <td>
                    <div>
                      {{ item.name }}
                    </div>
                  </td>
                  <td>
                    <div>
                      <v-text-field
                        v-model="item.value"
                        :hide-details="true"
                        dense
                        single-line
                        v-if="item.edit"
                      ></v-text-field>
                      <span v-else>
                        {{ item.value }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <v-icon
                        color=""
                        v-if="!item.edit"
                        @click="item.edit = true"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        color=""
                        v-if="item.edit"
                        @click="item.edit = false; attribute.edit = false"
                      >
                        mdi-window-close
                      </v-icon>
                      <v-icon
                        color=""
                        v-if="item.edit"
                        @click="saveAttribute(item)"
                      >
                        mdi-content-save
                      </v-icon>
                      <v-icon
                        color="red"
                        v-if="item.edit"
                        @click="deleteAttribute(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </template>

          <template v-slot:footer>
            <v-row class="mt-2" align="center" justify="center">
              <span class="grey--text">Show</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    text
                    color="grey"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-spacer></v-spacer>

              <span class="mr-4 grey--text">
                Page {{ page }} of {{ numberOfPages }}
              </span>
              <v-btn
                fab
                dark
                color="grey "
                class="mr-1"
                @click="formerPage"
                small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                fab
                small
                dark
                color="grey "
                class="ml-1"
                @click="nextPage"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>

    <!-- DIALOG FOR ATTRIBUTE CREATION  -->
    <v-dialog v-model="createAttributeDialog" persistent max-width="490">
      <v-card>
        <v-card-title class="text-h5"> Create new attribute </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="newAttribute.name"
                :rules="attributeNameRules"
                :counter="20"
                label="Name"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newAttribute.value"
                :counter="100"
                label="Value"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="createAttributeDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="createAttribute(newAttribute)"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>





<script>
// import AttributesEdit from "@/components/entities/AttributesEdit";

export default {
  props: ["entity"],
  components: {},

  data: function () {
    return {
      createAttributeDialog: false,
      defaultAttribute: {
        name: "",
        value: "",
        edit: false,
      },
      attributeNameRules: [
        (v) => !!v || "Name is required",
        (v) => (v || "").indexOf(" ") < 0 || "No spaces are allowed",
      ],
      newAttribute: {
        name: "",
        value: "",
        edit: false,
      },
      itemsPerPageArray: [10, 25, 50],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 10,
      sortBy: "name",
      keys: ["Name", "Value", "Action"],
      items: [],
    };
  },
  created() {
    this.initialize();
  },
  watch: {},
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    initialize() {
      this.items = [];
      //change attribute object to key/value object
      let i = 1;
      for (const property in this.entity.attributes) {
        let val = {
          id: i,
          name: property,
          value: this.entity.attributes[property],
          edit: false,
        };
        this.items.push(val);
        i = i + 1;
      }
    },

    //add attribute to list
    addAttribute() {
      this.newAttribute = { ...this.defaultAttribute };
      this.createAttributeDialog = true;
    },

    //edit selected attributes
    createAttribute(attribute) {
      this.$store.commit("showLoader");
      console.log(attribute);

      // prepare commiting to API
      let data = {};
      data = {
        value: attribute.value,
      };

      this.$http({
        method: "post",
        url:
          "/api/entities/" +
          this.entity.ident +
          "/attributes/" +
          attribute.name,
        data: data,
      })
        .then((result) => {
          //On traite la suite une fois la réponse obtenue
          data = result.data;
          console.log(data);
          this.createAttributeDialog = false;
          this.items.push(attribute);
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
      //   }
      //refresh entity info
      this.$store.commit("hideLoader");
    },

    //edit selected attributes
    saveAttribute(attribute) {
      this.$store.commit("showLoader");
      console.log(attribute);

      // prepare commiting to API
      let data = {};
      data = {
        value: attribute.value,
      };

      this.$http({
        method: "post",
        url:
          "/api/entities/" +
          this.entity.ident +
          "/attributes/" +
          attribute.name,
        data: data,
      })
        .then((result) => {
          //On traite la suite une fois la réponse obtenue
          attribute.edit = false;
          data = result.data;
          console.log(data);
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
      //   }
      //refresh entity info
      this.$store.commit("hideLoader");
    },

    //edit shown entity
    deleteAttribute(attribute) {
      this.$store.commit("showLoader");
      console.log(attribute);

      // prepare commiting to API

      this.$http({
        method: "delete",
        url:
          "/api/entities/" +
          this.entity.ident +
          "/attributes/" +
          attribute.name,
      })
        .then(() => {
          attribute.edit = false
          //On traite la suite une fois la réponse obtenue
        })
        .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
        });
      //   }
      //refresh entity info
      this.$store.commit("hideLoader");
    },
  },
};
</script>







