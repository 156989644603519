import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Remote from '../views/Remote.vue'
import Entities from '../views/Entities.vue'
import PageNotFound from '../views/PageNotFound.vue'
import User from '../views/User.vue'


const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: "Alemca Console - Home"
        }
    },
    {
        path: '/entities/:ident?',
        name: 'Entities',
        component: Entities,
        meta: {
            title: "Alemca Console - Entities"
        }
    },
    {
        path: '/remote/:ident?',
        name: 'Remote',
        component: Remote,
        meta: {
            title: "Alemca Console - Remote"
        }
    },
    {
        path: '/user',
        name: 'User',
        component: User,
        meta: {
            title: "Alemca Console - User"
        }
    },
    {
        path: '/:catchAll(.*)*',
        name: "PageNotFound",
        component: PageNotFound,
    }
]


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes
})


export default router